import { Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, createTranslateLoader } from './app.component';
// import { IonicStorageModule } from '../../node_modules/@ionic/storage';

import { IonicStorageModule } from 'node_modules/@ionic/storage';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Device } from '../../node_modules/@ionic-native/device/ngx';
import { SafariViewController } from '../../node_modules/@ionic-native/safari-view-controller/ngx';
import { CommonServicesProvider } from './providers/common.services/common.services';
import { OfflineServicesProvider } from './providers/offline-services/offline-services';
import { Network } from '../../node_modules/@ionic-native/network/ngx';
// import { FileTransferObject } from '../../node_modules/@ionic-native/file-transfer/ngx';
// import { FingerprintAIO } from '../../node_modules/@ionic-native/fingerprint-aio/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { DatePipe, CommonModule } from '../../node_modules/@angular/common';
import { HttpClientModule, HttpClient } from '../../node_modules/@angular/common/http';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// import { TranslateModule, TranslateLoader, TranslateService } from '../../node_modules/@ngx-translate/core';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
// import { FCM } from '@ionic-native/fcm/ngx';
import { SignaturePad, SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { MeetingListProvider } from './providers/meeting-list/meeting-list';
// import { FormsModule, ReactiveFormsModule } from '../../node_modules/@angular/forms';
import { FormsModule, ReactiveFormsModule } from 'node_modules/@angular/forms';
import { SettingComponent } from './setting/setting.component';
import { LoginComponent } from './login/login.component';
import { ReversePipe } from './pipes/reverse/reverse';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';
import { DragulaModule } from 'ng2-dragula';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ForgotSitePage } from './forgot-site/forgot-site.page';
// import { Calendar } from '@ionic-native/calendar/ngx';
import { NgOtpInputModule } from 'ng-otp-input';
import { Socket } from 'ng-socket-io';
import { SocketServiceService } from './providers/socket-service.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ForgetPasswordPage } from './forget-password/forget-password.page';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AttendeesModalPage } from './attendees-modal/attendees-modal.page';
import { HTTP } from '@ionic-native/http/ngx';


 //const config: SocketIoConfig = { url: 'https://sicotest.alpha-meeting.com', options: { transports: ['websocket'], path: '/api/chat' } };

//  const config: SocketIoConfig = { url: ' https://api2.sicotest.alpha-meeting.com', options: { transports: ['websocket'], path: '/api/chat' } };


 //matahintest  Test
// const config: SocketIoConfig = { url: 'https://api.matahintest.alpha-meeting.com', options: { transports: ['websocket'], path: '/api/chat' } };



// const config: SocketIoConfig = { url: 'http://localhost:3001', options: { transports: ['websocket'], path: '/api/chat' } };
//const config: SocketIoConfig = { url: 'https://api.sico.alpha-meeting.com/api', options: { transports: ['websocket'], path: '/api/chat' } };

// const config: SocketIoConfig = { url: 'https://apiv1.sico.alpha-meeting.com/api', options: { transports: ['websocket'], path: '/api/chat' } };


//matahintest  Prod
// const config: SocketIoConfig = { url: 'https://api.matahin.alpha-meeting.com', options: { transports: ['websocket'], path: '/api/chat' } };


//ajm  test
// const config: SocketIoConfig = { url: 'https://ajmtest.alpha-meeting.com', options: { transports: ['websocket'], path: '/api/chat' } };


//ajm  prod
//  const config: SocketIoConfig = { url: 'https://ajm.alpha-meeting.com', options: { transports: ['websocket'], path: '/api/chat' } };


//BNI PROD
// const config: SocketIoConfig = { url: 'https://cloud.alpha-meeting.com', options: { transports: ['websocket'], path: '/api/chat' } };

//BNI Test
//const config: SocketIoConfig = { url: 'http://cloud.alpha-meeting.com:3010', options: { transports: ['websocket'], path: '/api/chat' } };
//const config: SocketIoConfig = { url: 'http://localhost:3001', options: { transports: ['websocket'], path: '/api/chat' } };
//const config: SocketIoConfig = { url: 'http://108.61.149.158:8181', options: { transports: ['websocket'], path: '/api/chat' } };

// export function callConfig() {
//   //Do what you need here
//   let entityDetails = JSON.parse(localStorage.getItem('sitename'));
//   const config: SocketIoConfig = { url: entityDetails.api_url, options: { transports: ['websocket'], path: '/api/chat' } };
//   return () => {
//     return config;
//   }
// }

@Injectable()
export class SocketOne extends Socket {
    constructor() {
      let entityDetails = JSON.parse(localStorage.getItem('sitename'));
        super({ url: entityDetails.api_url, options: { transports: ['websocket'], path: '/api/chat' } });
    }

}


@NgModule({
  declarations: [AppComponent,SignaturePadComponent,SettingComponent,LoginComponent,ForgotSitePage,ForgetPasswordPage,AttendeesModalPage],
  entryComponents: [],
  imports: [BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
     IonicModule.forRoot(),
     HttpClientModule,
      AppRoutingModule,
      // IonicModule.forRoot({
      //   mode: 'ios',
      //   scrollPadding: false,
      //   scrollAssist: true
      // }),

      SignaturePadModule,
      // IonicStorageModule.forRoot(),
      IonicStorageModule.forRoot({
        driverOrder: ['indexeddb', 'sqlite', 'websql']
      }),
      NgCircleProgressModule.forRoot({
        "radius": 60,
        "space": -10,
        "outerStrokeGradient": true,
        "outerStrokeWidth": 10,
        "outerStrokeColor": "#4882c2",
        "outerStrokeGradientStopColor": "#53a9ff",
        "innerStrokeColor": "#e7e8ea",
        "innerStrokeWidth": 10,
        "title": "UI",
        "animateTitle": false,
        "animationDuration": 1000,
        "showUnits": false,
        "showBackground": false,
        "clockwise": false,
        "startFromZero": false,
        "lazy": false}),
      // SocketIoModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }),
     ],
  providers: [
    StatusBar,
    SplashScreen,
    // FCM,
    SignaturePad,
    TranslateService,
    File,
    FileOpener,
    Network,
    FileTransfer,
     Calendar,
     EmailComposer,
     Keyboard,
  FingerprintAIO,
    DatePipe,
    ReversePipe,
    Device,
    NavParams,
     SafariViewController,
     CommonServicesProvider,
     OfflineServicesProvider,
     MeetingListProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SocketServiceService,
    InAppBrowser,
    HTTP
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
