import { Component, OnInit } from '@angular/core';
import { OfflineServicesProvider } from '../providers/offline-services/offline-services';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import * as _ from 'lodash';
// import { AlertController, LoadingController, NavController, Platform } from '../../../node_modules/@ionic/angular';
import { LoadingService } from '../providers/common.services/loading.service';
import { CommonServicesProvider } from '../providers/common.services/common.services';
// import { Socket } from '../../../node_modules/ngx-socket-io';
// import { TranslateService } from '../../../node_modules/@ngx-translate/core';
import { FingerprintAIO, FingerprintOptions } from '@ionic-native/fingerprint-aio/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NewCommonService } from '../providers/common.services/new-common.service';
import { environment } from 'src/environments/environment';
import { SocketServiceService } from '../providers/socket-service.service';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { Socket } from 'ngx-socket-io';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, LoadingController, NavController, Platform } from '@ionic/angular';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  userid: any = '';
  password: any = '';
  wikitudePlugin: any;
  fingerPrintOptions: FingerprintOptions;
  ShowRegister: any;
  version :any ='7.0.0';
  isKeyboardHide=true;
  c_year:any= new Date().getFullYear();
  version_new:any = environment?.version;
  platform_hide:boolean = true;
  isoffline_toggle:boolean=false;

  constructor(private offlineServicesProvider: OfflineServicesProvider,
    private loading: LoadingService,
    private socket: SocketServiceService,
    private platform: Platform,
    private navCtrl: NavController,
    private router: Router,
    public keyboard:Keyboard, 
    private translate: TranslateService,
    public serviceProvider: CommonServicesProvider,
    // private storage: NativeStorage,
    private fingerprintAIO: FingerprintAIO,
    private newCommonservice: NewCommonService,
    public alertCtrl: AlertController,
    private iab: InAppBrowser
  ) {
    localStorage.setItem("isOffline", "false");
    this.fingerPrintOptions = { title: 'BioMetric Auth', subtitle: "AlphaMeeting 7.0.0" };
    localStorage.setItem("isPassCodeEnabled", "false");

    if(localStorage.getItem('switchRole')){
      this.sitehisData = JSON.parse(localStorage.getItem('switchRole'));
    }

  }

  getCommonurl(){
    let url = this.serviceProvider.getURL();
    console.log('loginpage ',url)
  }


  ngOnInit() { 
    console.log("this.user",this.userid);
    console.log("this.password",this.password)
    this.getCommonurl();

    this.platform.ready().then(() => {
      if(this.platform.is('android') || this.platform.is('ios')){
        this.platform_hide = true
      }else{
        this.platform_hide = false
      }
    })
  }


  ionViewWillEnter() {
    this.keyboard.onKeyboardWillShow().subscribe(()=>{
      this.isKeyboardHide=false;
      // console.log('SHOWK');
    });

    this.keyboard.onKeyboardWillHide().subscribe(()=>{
      this.isKeyboardHide=true;
      // console.log('HIDEK');
    });
  }


  async ionViewDidLoad() {
    localStorage.setItem("isOffline", "false");
    console.log('ionViewDidLoad LoginPage');
    if (localStorage.getItem("isPassCodeEnabled") == "true") {
      let passcode = await localStorage.getItem('passcode');
      if (!passcode) {
        // this.navCtrl.setRoot(PasscodePage, null); 
      }
    }
    if (this.offlineServicesProvider.getAppReviewConfig()) {
      this.ShowRegister = true;
    }
  }

  Register() {
    localStorage.removeItem("isReview")
    // this.navCtrl.setRoot(SignupPage);
  }

  setOffline(isOffline) {
    console.log("isOffline",isOffline.detail.checked);
    this.isoffline_toggle = isOffline.detail.checked;
    localStorage.setItem("isOffline", isOffline.checked);
  }


  async fingerPrintLogin() {
    try {
      if (localStorage.getItem("logininfo") != null) {
          await this.platform.ready();
          this.fingerprintAIO.isAvailable().then(
            (isAvailableSuccess) => {
              console.log("fingerprintAIO isAvailableSuccess" + isAvailableSuccess);
              localStorage.setItem("isFingerPrintAvailable", "true");
              localStorage.setItem("securityEnabled", "true");
             
            //  this.fingerPrintOptions = { clientId: localStorage.getItem("userid") };
              let logininfo = JSON.parse(localStorage.getItem("logininfo"));
              var userdata = logininfo[logininfo.length - 1];
              console.log("fingerprintAIO data" + userdata);
              // this.fingerPrintOptions = { clientId: userdata.userId };
              if (localStorage.getItem("securityEnabled") == "true" || localStorage.getItem("securityEnabled") == null) {
              this.fingerprintAIO.show(this.fingerPrintOptions).then(data => {
                console.log("fingerprintAIO data" + data);
               //console.log("fingerprintAIO data" + data);
               // console.log("fingerprintAIO data" + logininfo);
              this.userid = userdata.userId;
              this.password = userdata.pass;
              this.callSubmitWebService();
              //this.offlineServicesProvider.offlineServiceFetch();
              }).catch(error => {
                this.serviceProvider.logout();
                console.log("fingerprintAIO error" + error);
                let msg = this.translate.instant('APP.FPERROR');
                this.serviceProvider.showToastMessage(msg);
              })
            }
          },
            (isAvailableError) => { 
              console.log("fingerprintAIO isAvailableError" + isAvailableError);
              this.serviceProvider.logout();
              let msg = this.translate.instant('APP.FPERROR');
              this.serviceProvider.showToastMessage(msg);
             }
          );  
        }
          else {
          let msg = this.translate.instant('APP.FPPRELOGININFO');
          this.serviceProvider.showToastMessage(msg);
          }
        } catch (error) {
          //this.offlineServicesProvider.offlineServiceFetch();
         let msg = this.translate.instant('APP.FPNOTAVAILABLE');
         this.serviceProvider.showToastMessage(msg);
         console.log("checkFingerPrint" + error);

        }
      }





  async callSubmitWebService() {
    // this.navCtrl.push(TabsPage);
    let userId = _.toLower(this.userid);
    let passcodekey = await localStorage.getItem('passcodeKey');
    var request = {
      pass: this.password,
      userId: userId,
      accessKey: passcodekey,
      appVersion:this.version

    };
    var isOffline = await localStorage.getItem("isOffline");
    console.log('request', request);
    if (isOffline == "false") {
      this.loading.present();
      let url = "api/login/appLogin";
      this.serviceProvider.postWebService(url, request, '').subscribe(
        async (data: any) => {
          console.log('callSubmitWebService this.data' + JSON.stringify(data));
          this.loading.dismiss();
          if(data && data != 'undefined'){
            if(data?.is_force_change_password == 1){
              let alert =  await this.alertCtrl.create({
                header: this.translate.instant('COMMON.FORCETITLE'),
                subHeader: this.translate.instant('COMMON.FORCEMSG'),
                //  enableBackdropDismiss: false,
                buttons: [{
                  text: this.translate.instant('COMMON.OK'),
                  handler: () => {
                    this.navCtrl.navigateRoot('/setting');
                  }
                }]
              });
               await alert.present();
              
            }else{
              this.navCtrl.navigateRoot('/tabs');
            }
          
          // this.navCtrl.push(TabsPage);

          this.saveLocalUserData(request);
          localStorage.setItem("userid", request.userId);
          localStorage.setItem(request.userId, JSON.stringify(data));

          this.saveSitenamewithuser(request) // switch role condition added 

          if (this.platform.is("cordova")) {
            let entity = JSON.parse(localStorage.getItem('sitename'))
            window["plugins"].OneSignal.setExternalUserId(entity.entity_name+"_"+request.userId);
          }
          this.makeSocketConnection(request, data);
          // localStorage.setItem("userinfo", JSON.stringify(data));
          localStorage.setItem("user_name", data.user_name);
          localStorage.setItem("token", data.token);
          localStorage.setItem("jwtToken", data.jwtToken);
          this.offlineServicesProvider.offlineServiceFetch();
          }
        },
        (error) => {
          console.log("error",error)
          this.loading.dismiss();
          if (error.status == 401) {
            localStorage.setItem('passcodeKey', null);
            localStorage.setItem('passcode', null);
            //  this.navCtrl.setRoot(PasscodePage, null);
            this.navCtrl.navigateRoot('/passcode');
          } else {
            this.serviceProvider.errorHandling(error);
          }
        }
    )
    //  });
    } else {
      console.log('check offline true');
      this.loading.present();
      // loader.present().then(() => {
      let isExist = this.saveLocalUserData(request);
      if (isExist == undefined) {
        this.loading.dismiss();
        let message = this.translate.instant('APP.InvalidCredentialsDesc');
        this.serviceProvider.showToastMessage(message);
      } else {
        localStorage.setItem("userid", request.userId);
        var data = JSON.parse(localStorage.getItem(request.userId));
        localStorage.setItem("user_name", data.user_name);
        localStorage.setItem("token", data.token);
        localStorage.setItem("jwtToken", data.jwtToken);
        this.loading.dismiss();
        //   this.navCtrl.push(TabsPage);
        this.navCtrl.navigateRoot('/tabs');
        let msg = this.translate.instant('APP.OfflineLogin');
        this.serviceProvider.showToastMessage(msg);
        this.socket.disconnect();
      }
      // });
    }
  }


  sitehisData:any = []
  saveSitenamewithuser(user){
    // this.sitehisData = localStorage.getItem("switchData") != null ? JSON.parse(localStorage.getItem("switchData")) : [];
    let entity = JSON.parse(localStorage.getItem('sitename'))
    let checkAl = this.sitehisData.filter((res)=> res.entity_name == entity.entity_name);
    console.log('vengi switchrole1',checkAl)
    if(checkAl.length == 0){
      entity.userId = user.userId;
      this.sitehisData.push(entity)
      localStorage.setItem('switchRole',JSON.stringify(this.sitehisData))
    }else{
      // check user 
      let checkuser = this.sitehisData.filter((res)=> res.userId == user.userId);
      console.log('vengi switchrole2',checkuser)
      if(checkuser.length == 0){
        entity.userId = user.userId;
        this.sitehisData.push(entity)
        localStorage.setItem('switchRole',JSON.stringify(this.sitehisData))
      }
    }

    
  }


  saveLocalUserData(request) {
    var logininfo = localStorage.getItem("logininfo") != null ? JSON.parse(localStorage.getItem("logininfo")) : [];
    var isExist = _.find(logininfo, request);
    console.log("isExist", isExist);
    if (isExist == undefined) {
      logininfo.push(request);
      localStorage.setItem("logininfo", JSON.stringify(logininfo));
    } else {
      console.log("User already exist");
    }
    return isExist;
  }

  makeSocketConnection(request, response) {
    this.socket.connect();
    this.socket.emit('set-username', { user_id: request.userId, token: response.token, user_name: response.user_name });
  }


  siteBack(){
    this.navCtrl.navigateRoot('/first-screen');
  }

  forgot(){
    this.router.navigate(['/forget-password'])
  }

  goToLink(url: string)
  {
    console.log('hi')
    window.open(url, "_blank");
    this.iab.create(url).show();
    // this.iab.open(url,'_system')
 }
}
