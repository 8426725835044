import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../providers/common.services/language.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-attendees-modal',
  templateUrl: './attendees-modal.page.html',
  styleUrls: ['./attendees-modal.page.scss'],
})
export class AttendeesModalPage implements OnInit {
@Input() List :any;
  lang:any;
  constructor(public translate: TranslateService,private langDir: LanguageService,public modalController: ModalController) {
    this.lang = localStorage.getItem('lang') == null ? 'en' : localStorage.getItem('lang');
      langDir.SiteLanguage$.subscribe(data => {
        console.log('vengi', data);
        if (data === 'ltr') {
          this.lang = 'en';
          this.translate.setDefaultLang('en');
          this.translate.use('en');
          // this.goBackToList();
        }
        else {
          this.lang = 'ar';
          this.translate.setDefaultLang('ar');
          this.translate.use('ar');
          // this.goBackToList();
        }
      });
      console.log('vengitest==>',this.lang)
      // this.goBackToList();
   }

  ngOnInit() {
    console.log(this.List)
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  getBackgroundColor(index: number): string {
    const colors = ['#7348FC','#00D28E', '#FF2C62', '#FFAE00'];
    return colors[index % colors.length];
  }
}
