import { Injectable } from '@angular/core';

/*
  Generated class for the MeetingListProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class MeetingListProvider {

  meetingList = [];
  activeMeeting = null;
  constructor() {
    console.log('Hello MeetingListProvider Provider');
  }

  setMeeting(meetingList, activeMeeting) {
    this.meetingList = meetingList;
    this.activeMeeting = activeMeeting;
  }

  getMeeting() {
    var obj = {
      'meetingList': this.meetingList,
      'activeMeeting': this.activeMeeting
    };
    return obj;
  }
}
