import { Injectable } from '@angular/core';
// import { Http, Response, Headers, RequestOptions, ResponseOptions, ResponseContentType } from '@angular/http';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
// import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';
import { Network } from '@ionic-native/network/ngx';
import { Socket } from 'ngx-socket-io';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { combineAll, map } from 'rxjs/operators';
import { tap, catchError } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import { ToastController, NavController } from '../../../../node_modules/@ionic/angular';
// import { LoginComponent } from '../../login/login.component';
import { Router } from '../../../../node_modules/@angular/router';
import { LanguageService } from './language.service';
import { environment } from '../../../environments/environment';
import { emit } from 'process';
import { SocketServiceService } from '../socket-service.service';
import { StorageService } from './storage.service';

declare var RadaeePDFPlugin;
/*
  Generated class for the CommonServicesProvider provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular DI.
*/
@Injectable()
export class CommonServicesProvider {
  headers: Headers;
  common: string;
  moe: string;
  sessionID: string;
  // commonUrl: string = "http://108.61.149.158:3005/"; //mssql
  //commonUrl: string = "http://108.61.149.158:3000/"; //mysql
  //commonUrl: string = "http://108.61.149.158:3002/"; //oracle
  //  commonUrl: string = "http://localhost:3001/";

  // commonUrl: string = "https://sico.alpha-meeting.com/";
  //commonUrl: string = "http://172.25.1.93:3000/";
  // commonUrl: string = "http://cloud.alpha-meeting.com:3010/"; //BNI test
  //  commonUrl: string = "https://cloud.alpha-meeting.com/"; //BNI prod
  // commonUrl :string ="https://api.matahintest.alpha-meeting.com/"; // AL-matahin test
  // commonUrl: string ="https://api.matahin.alpha-meeting.com/";  //AL-matahin prod
  // commonUrl: string ="https://api.bni.alpha-meeting.com/"
  //commonUrl: string = "https://emcs.ega.gov.bh/";
  //commonUrl: string = "http://108.61.149.158:8181/";
  // commonUrl: string = "https://alphameeting.ega.gov.bh/"; //IGA & MOW
  //  commonUrl: string = "https://sicotest.alpha-meeting.com/";    //AWS SICO Test
  //  commonUrl:string = "https://api2.sicotest.alpha-meeting.com/"
  // commonUrl: string = "https://api.bnitest.alpha-meeting.com/";    //AWS BNI Test
  //  commonUrl: string ="https://api.bni.alpha-meeting.com/" ; // AWS BNI PROD
  // commonUrl: string ="https://api.sico.alpha-meeting.com/" ; // AWS SICO PROD
  //  commonUrl: string ="https://apiv1.sico.alpha-meeting.com/" ; // AWS SICO PROD
  // commonUrl: string ="https://ajmtest.alpha-meeting.com/" ; // AJM TEST
  //  commonUrl: string ="https://ajm.alpha-meeting.com/" ; // AJM PROD
  commonUrl: string;


  //    https://api.sicotest.alpha-meeting.com/api/

  entityDetails: any;

  constructor(private http: HttpClient,
    private toastController: ToastController,
    // private app: App,
    private socket: SocketServiceService,
    private translate: TranslateService,
    private router: Router,
    private storage: Storage,
    private navCtrl: NavController,
    private langDir: LanguageService,
    private transfer: FileTransfer,
    private network: Network) {
    console.log('Language ', localStorage.getItem('lang'));
    this.entityDetails = JSON.parse(localStorage.getItem('sitename'));
    console.log('entiryDetails ', this.entityDetails);
    this.getURL();
  }

  //call entity URL

  public getURL() {
    console.log('vengi common services')
    if (localStorage.getItem('sitename')) {
      this.entityDetails = JSON.parse(localStorage.getItem('sitename'));
      this.commonUrl = this.entityDetails.api_url;
    }

    return this.commonUrl
  }

  getWebService(serviceURL, serviceName): Observable<any> {
    if (this.isOnline()) {
      let url = this.commonUrl + serviceURL;
      console.log('getWebService url:', url);
      let options = this.resetHeader(serviceName);
      return this.http.get(url, options).pipe(
        map(data => this.handleData(data)),
        tap(_ => console.log('test')),
        catchError(this.handleError('err', []))
      );
    } else {
      return Observable.throw('internetError');
    }
  }

  getWebfileService(serviceURL, serviceName): Observable<any> {
    if (this.isOnline()) {
      let url = this.commonUrl + serviceURL;
      console.log('getWebService url:', url);
      let options = this.resetHeader(serviceName);
      return this.http.get(url, options).pipe(
        map(data => this.handleData(data)),
        tap(_ => console.log('test')),
        catchError(this.handleError('err', []))
      );
    } else {
      return Observable.throw('internetError');
    }
  }

  getVersiondetails(serviceURL, serviceName): Observable<any> {
    if (this.isOnline()) {
      let url = serviceURL;
      console.log('getWebService url:', url);
      let options = this.resetHeader(serviceName);
      return this.http.get(url, options).pipe(
        map(data => this.handleData(data)),
        tap(_ => console.log('test')),
        catchError(this.handleError('err', []))
      );
    } else {
      return Observable.throw('internetError');
    }
  }
  getEntity(sitename): Observable<any> {
    if (this.isOnline()) {
      let url = environment?.baseUrl + 'entity/fetchEntityDetails/' + sitename;
      console.log('getWebService url:', url);
      let options = this.resetHeader('');
      return this.http.get(url).pipe(
        map(data => this.handleData(data)),
        tap(_ => console.log('test')),
        catchError(this.handleError('err', []))
      );
    } else {
      return Observable.throw('internetError');
    }
  }

  forgotEntity(email): Observable<any> {
    if (this.isOnline()) {
      let url = environment?.baseUrl + 'entity/forgotEntity/' + email;
      console.log('getWebService url:', url);
      let options = this.resetHeader('');
      return this.http.post(url, {}).pipe(
        map(data => this.handleData(data)),
        tap(_ => console.log('test')),
        catchError(this.handleError('err', []))
      );
    } else {
      return Observable.throw('internetError');
    }
  }

  forgotPassword(email,user): Observable<any> {
    if (this.isOnline()) {
      let url = this.commonUrl + 'api/entity/forgotPasswordOtpEmail/'+email+'/'+user;
      // console.log('getWebService url:', url);
     let options = this.resetHeader('');
      return this.http.post(url,{}).pipe(
        map(data =>this.handleData(data)),
        tap(_ => console.log('test')),
       catchError(this.handleError('err', []))
      );
    } else {
      return Observable.throw('internetError');
    }
  }

  resetPassword(body): Observable<any> {
    if (this.isOnline()) {
      let url = this.commonUrl + 'api/entity/resetForgotPassword';
      // console.log('getWebService url:', url);
     let options = this.resetHeader('');
      return this.http.post(url,body).pipe(
        map(data =>this.handleData(data)),
        tap(_ => console.log('test')),
       catchError(this.handleError('err', []))
      );
    } else {
      return Observable.throw('internetError');
    }
  }

  switchRole(body,urls): Observable<any> {
    if (this.isOnline()) {
      let url = urls + 'api/login/switchEntity';
      // console.log('getWebService url:', url);
     let options = this.resetHeader('');
      return this.http.post(url,body).pipe(
        map(data =>this.handleData(data)),
        tap(_ => console.log('test')),
       catchError(this.handleError('err', []))
      );
    } else {
      return Observable.throw('internetError');
    }
  }

  getPdfWebService(serviceURL, serviceName): Observable<any> {
    if (this.isOnline()) {
      let url = this.commonUrl + serviceURL;
      console.log('getWebService url:', url);
      // let authToken = localStorage.getItem('jwtToken') != null ? localStorage.getItem('jwtToken') : null;
      // this.headers = new Headers({
      //   //'Content-Type': 'application/pdf',
      //   'Accept-Language': localStorage.getItem('lang'),
      //   'Authorization': "Bearer " + authToken
      // });
      // let options = this.resetHeader(serviceName);
      let authToken = localStorage.getItem('jwtToken') != null ? localStorage.getItem('jwtToken') : null;
      let headers = new HttpHeaders().set('Authorization', "Bearer " + authToken).set('Accept-Language', localStorage.getItem('lang'));
      let options = { headers: headers, responseType: 'blob' as 'json' }
      //  let options = new RequestOptions({ headers: this.headers, responseType: ResponseContentType.Blob });
      console.log('this.options:', options);
      // return this.http.get(url, options)
      //   .do((data: Response) => console.log('getFromWebService data' + data))
      //   // .map(this.extractData)
      //   //.do((data: Response) => console.log('getFromWebService data' + data))
      //   .catch(this.handleError);
      return this.http.get(url, options).pipe(
        map(data => this.handleData(data)),
        tap(_ => console.log('test')),
        catchError(this.handleError('err', []))
      );
    } else {
      //   let responseOptions = new HttpResponse({ status: 5030 });
      //   let internetError: Response = new Response(responseOptions);
      // return Observable.throw('internetError');
      this.translate.get('COMMON.INTERNETERROR').subscribe((res: string) => {
        let message = res;
        this.showToastMessage(message);
      });
    }
  }


  getImageWebService(serviceURL, serviceName): Observable<any> {
    if (this.isOnline()) {
      let a
      let url = this.commonUrl + serviceURL;
      console.log('getWebService url:', url);
      let authToken = localStorage.getItem('jwtToken') != null ? localStorage.getItem('jwtToken') : null;
      let headers = new HttpHeaders().set('Authorization', "Bearer " + authToken).set('Accept-Language', localStorage.getItem('lang'));
      let options = { headers: headers }
      console.log('this.headers:', this.headers);
      return this.http.get(url, options).pipe(
        map(
          data => this.handleData1(data)),
        tap(_ => console.log('test')),
        catchError(data => a)
        //this.handleError('err', []))
      );
    } else {
      this.translate.get('COMMON.INTERNETERROR').subscribe((res: string) => {
        let message = res;
        this.showToastMessage(message);
      });
    }
  }



  postWebService(serviceURL, params: Object, serviceName): Observable<any> {
    if (this.isOnline()) {
      let url = this.commonUrl + serviceURL;
      console.log('vengitest', url)
      let options = this.resetHeader(serviceName);
      return this.http.post(url, params, options).pipe(
        map(data => this.handleData(data)),
        tap(_ => console.log('test')),
        catchError(this.handleError('err', []))
      );
    } else {
      this.translate.get('COMMON.INTERNETERROR').subscribe((res: string) => {
        let message = res;
        this.showToastMessage(message);
      });
    }
  }


  updateProfilephoto(fileData:any): Observable<any> {

    // console.log('FROM file upload ==>', fileData);
    let url =  this.commonUrl  + 'api/file/userPicUpload'
    return this.http.post<any>(url, fileData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
      },
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event:any) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);

          // console.log('progress ')

          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          // console.log('response ', event.body);
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    }),

      catchError(this.handleError('err', []))
    );

  }


  private handleData(res) {
    console.log("extractData", res)
    if (res.name === "Error") {
      let message = this.translate.getDefaultLang() == 'en' ? res.message : res.messageAr;
      this.showToastMessage(message);
      console.log("extractData res.status ", res.status)
    } else {
      console.log("extractData else ", res.body)
      return res;
    }
  }

  private handleData1(res) {
    console.log("extractData", res)
    if (res.name === "Error") {
      // let  message =  this.translate.getDefaultLang() == 'en' ? res.message : res.messageAr;
      // this.showToastMessage(message);
      console.log("extractData res.status ", res.status)
    } else {
      console.log("extractData else ", res.body)
      return res;
    }
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (err: any): Observable<T> => {
      console.error('SERVER ERR', err); // log to console instead ad
      console.log(`${operation} failed: ${err.message}`);
      if (err.status == 500) {
        // this.snackBar.open(err.error.message, 'close');
        return err.error.message;
      }
      if (err.status == 400) {
        console.log("400------")
        let message = this.translate.getDefaultLang() == 'en' ? err.error.message : err.error.messageAr;
        this.showToastMessage(message);
      }

      if (err.status == 0) {
        let message = this.translate.instant('COMMON.ERROR');
        this.showToastMessage(message);
      }

      if (err.status == 401) {
        let body = err.json();
        let message = this.translate.getDefaultLang() == 'en' ? body.message : body.messageAr;
        this.showToastMessage(message);
        this.logout();

      } else {
        //alert(err.message);
        //  alert('Server Connection Problem. Please contact Admin');
        return;
      }
      return of(result as T);
    };
  }




  postPDFWebService(serviceURL, params: Object, serviceName): Observable<any> {
    if (this.isOnline()) {
      params = JSON.stringify(params);
      let url = this.commonUrl + serviceURL;
      console.log('postWebService url:', url);
      let authToken = localStorage.getItem('jwtToken') != null ? localStorage.getItem('jwtToken') : null;
      let headers = new HttpHeaders().set('Content-Type', 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW').set('Authorization', "Bearer " + authToken).set('Accept-Language', localStorage.getItem('lang'));
      let options = { headers: headers }

      return this.http.post(url, params, options).pipe(
        map(data => this.handleData(data)),
        tap(_ => console.log('test')),
        catchError(this.handleError('err', []))
      );
    } else {
      this.translate.get('COMMON.INTERNETERROR').subscribe((res: string) => {
        let message = res;
        this.showToastMessage(message);
      });
    }
  }

  putWebService(serviceURL, params: Object, serviceName): Observable<any> {
    if (this.isOnline()) {
      params = JSON.stringify(params);
      let url = this.commonUrl + serviceURL;
      console.log('putWebService url:', url);
      let options = this.resetHeader(serviceName);
      return this.http.put(url, params, options).pipe(
        map(data => this.handleData(data)),
        tap(_ => console.log('test')),
        catchError(this.handleError('err', []))
      );
    } else {
      this.translate.get('COMMON.INTERNETERROR').subscribe((res: string) => {
        let message = res;
        this.showToastMessage(message);
      });
    }
  }


  deleteWebService(serviceURL, serviceName): Observable<any> {
    if (this.isOnline()) {
      let url = this.commonUrl + serviceURL;
      console.log('deleteWebService url:', url);
      let options = this.resetHeader(serviceName);
      //let options = new RequestOptions({ headers: this.headers });
      console.log('this.headers:', this.headers);
      // return this.http.delete(url, options)
      //   .do((data: Response) => console.log('deleteWebService data' + data))
      //   .map(this.extractData)
      //   //.do((data: Response) => console.log('getFromWebService data' + data))
      //   .catch(this.handleError);
      return this.http.delete(url, options).pipe(
        map(data => this.handleData(data)),
        tap(_ => console.log('test')),
        catchError(this.handleError('err', []))
      );
    } else {
      this.translate.get('COMMON.INTERNETERROR').subscribe((res: string) => {
        let message = res;
        this.showToastMessage(message);
      });
    }
  }

  // resetHeader(serviceName) {
  //   //this.headers.delete('Authorization');
  //   let authToken = localStorage.getItem('jwtToken') != null ? localStorage.getItem('jwtToken') : null;
  //   //let authToken =  "Bearer " + "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiemFoaWR0ZXN0MyIsInVzZXJfbmFtZSI6InphaGlkIG1hcWJvb2wiLCJlbWFpbF9pZCI6InNhbnRoYW5hbXRzQGdtYWlsLmNvbSIsInJvbGVfaWQiOiIxIn0.fVHJDU63W-WCiPgZMI0EUk97dSdpJC52UzjV2QrfP20";

  //   //let sessionID = localStorage.getItem('JSESSIONID') != null ? localStorage.getItem('JSESSIONID') : null;
  //   //let lang = localStorage.getItem('lang') == "en" ? "English" : "Arabic";
  //   // console.log('getWebService authToken:', authToken);
  //   this.headers.set('Content-Type', 'application/json');
  //   this.headers.set('Authorization', "Bearer " + authToken);
  //   // this.headers.set('JSESSIONID', sessionID);
  //   // this.headers.set('language', lang);
  //   this.headers.set('Accept-Language', localStorage.getItem('lang'));
  //   return this.headers;
  // }

  resetHeader(serviceName) {
    let authToken = localStorage.getItem('jwtToken') != null ? localStorage.getItem('jwtToken') : null;
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', "Bearer " + authToken).set('Accept-Language', localStorage.getItem('lang'));

    let options = { headers: headers }
    return options;
  }

  errorHandling(error: Response | any) {
    console.log('errorHandling error:', error);
    console.log('errorHandling error status:', error.status);

    let message = "";

    if (error.status == 5030) {
      this.translate.get('COMMON.INTERNETERROR').subscribe((res: string) => {
        message = res;
        this.showToastMessage(message);
      });
    } else if (error.status == 401 || error.name == 'AuthorizationRequiredError') {
      let body = error.json();
      message = this.translate.getDefaultLang() == 'en' ? body.message : body.messageAr;
      this.showToastMessage(message);
      this.logout();
    } else if (error.status == 202 || error.status == 400) {
      let body = error.json();
      console.log(body);
      message = this.translate.getDefaultLang() == 'en' ? body.message : body.messageAr;
      this.showToastMessage(message);
    } else if (error.status == 500) {
      let body = error.json();
      this.showToastMessage(error.statusText);
      console.log('error 500', error.statusText)
    } else {
      this.translate.get('COMMON.ERROR').subscribe((res: string) => {
        message = res;
        this.showToastMessage(message);
      });
    }
  }

  // async showToastMessage(message: any) {
  //   let toast = this.toastController.create({
  //     message: message,
  //     duration: 5000,
  //     position: 'bottom'
  //   });


  //   toast.onDidDismiss(() => {
  //     console.log('Dismissed toast');
  //   });
  //  await toast.present();
  // }


  async showToastMessage(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 6000,
      position: 'bottom',
      cssClass: 'custom-toast'

    });
    toast.present();
  }

  async showToastMessagenew(header, msg, type, position) {
    const toast = await this.toastController.create({
      header: header,
      message: msg,
      duration: 6000,
      position: position,
      color: type,
      cssClass: 'custom-toast'
    });
    toast.present();
  }


  isNotificationArrived() {
    return localStorage.getItem("isNotificationArrived") == "true" ? true : false;
  }


  private extractData(res: Response) {
    console.log("extractData", res)
    let body = res;
    if (res.status != 200) {
      console.log("extractData res.status ", body)
      throw Observable.throw(res);
    } else {
      console.log("extractData else ", body)
      return body || {};
    }
  }

  // private handleError(error: Response | any) {
  //   if (error.error) {
  //     return Observable.throw(error.error);
  //   } else {
  //     return Observable.throw(error);
  //   }
  // }

  private isOnline(): boolean {
    let online = this.network.type == "none" || this.network.type == "unknown" ? false : true;
    console.log("this.network.type", this.network.type);
    console.log("online", online);
    return online;
  }

  logout() {

    // this.router.navigate(['slide']);
    // let nav = this.app.getRootNavs()[0];
    // nav.setRoot(LoginComponent);
    //this.app.getRootNavs()[0].setRoot(LoginPage);
    localStorage.removeItem('userid');
    localStorage.removeItem("token");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("user_name");
    localStorage.removeItem("selectedTeam");
    localStorage.removeItem("currentMeetingLength");
    localStorage.removeItem("notificationRegisterID");
    localStorage.removeItem('sitename')
    // localStorage.removeItem('logininfo');
    localStorage.setItem('showMeetingFiles', JSON.stringify(true));
    localStorage.setItem("isOffline", "false");
    localStorage.setItem("lang", "en");
    this.langDir.setReadingDirection('ltr');
    this.navCtrl.navigateRoot('/');

    this.storage.clear();

    RadaeePDFPlugin.close({});

    this.socket.disconnect();
  }


  fileTransfer(openedFileUrl, openedFileItem) {
    const fileTransfer: FileTransferObject = this.transfer.create();
    let file = openedFileUrl;
    let splitArr = _.split(file, '/');
    let filename = splitArr[splitArr.length - 1];
    let authToken = localStorage.getItem('jwtToken') != null ? localStorage.getItem('jwtToken') : null;
    console.log('file ' + file);
    console.log('splitArr ' + splitArr);
    console.log('filename ' + filename);
    let options: FileUploadOptions = {
      fileKey: 'file',
      fileName: filename,
      chunkedMode: false,
      mimeType: 'application/pdf',
      headers: {
        'Authorization': "Bearer " + authToken
      }
    }
    let url = this.commonUrl + 'api/esignature/uploadfile/' + openedFileItem.documentId + '/' + openedFileItem.fileres.fileId;
    console.log('url ' + url);
    return fileTransfer.upload(file, url, options);
  }


  fileTransferBriefcase(openedFileUrl) {
    const fileTransfer: FileTransferObject = this.transfer.create();
    let file = openedFileUrl
    let splitArr = _.split(file, '/');
    let filename = splitArr[splitArr.length - 1];
    let authToken = localStorage.getItem('jwtToken') != null ? localStorage.getItem('jwtToken') : null;
    console.log('file ' + file);
    console.log('splitArr ' + splitArr);
    console.log('filename ' + filename);
    let options: FileUploadOptions = {
      fileKey: 'file',
      fileName: filename,
      chunkedMode: false,
      mimeType: 'application/pdf',
      headers: {
        'Authorization': "Bearer " + authToken
      }
    }
    let url = this.commonUrl + 'api/file/upload';
    console.log('url ' + url);

    console.log("fileTransfer.upload(file, url, options);", fileTransfer.upload(file.replaceAll(' ', '%20'), url, options))
    return fileTransfer.upload(file.replaceAll(' ', '%20'), url, options);
  }



  withoutpostData(url: string, pbody: any): Observable<any> {
    let token = localStorage.getItem('jwtToken');
    let jwtToken = 'Bearer ' + token
    // this.authorization = 'Bearer ' + jwtToken;
    const options = { 'headers': { 'Content-Type': 'application/json', 'Authorization': jwtToken } };

    url = this.commonUrl + url;
    return this.http.post(url, pbody,options).pipe(map(res => res),
    catchError(this.handleError('err', []))
    )
}

}
