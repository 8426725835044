import { Injectable, ViewChild } from '@angular/core';
import { CommonServicesProvider } from '../../providers/common.services/common.services'
// import { Storage } from '@ionic/storage';
// import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Storage } from '@ionic/storage';
// import { FCM } from '@ionic-native/fcm/ngx';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { AlertController, Platform } from '@ionic/angular';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/defer';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/mergeScan';
import 'rxjs/add/operator/publishReplay';
import 'rxjs/add/operator/startWith';
import { SocketServiceService } from '../socket-service.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../common.services/storage.service';
// import { SignupPage } from '../../pages/signup/signup';

/*
  Generated class for the OfflineServicesProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class OfflineServicesProvider {
  // @ViewChild('myNav') navCtrl: NavController;
  isPlatform = true;
  public appVersion: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  currentIosVersion: string;
  constructor(
    public storage: Storage,
    // private fcm: FCM,
    public serviceProvider: CommonServicesProvider,
    private safariViewController: SafariViewController,
    private alertCtrl: AlertController,
    private socket: SocketServiceService,
    private translate: TranslateService,
    private platform: Platform,
  ) {
    console.log('OfflineServicesProvider');
  }

  offlineServiceFetch() {
    var isOffline = localStorage.getItem("isOffline");
    if (isOffline == 'false') {
      this.getConfig();
      this.getDashBoardDetails();
      this.callMeetingWebService();
      this.callApprovalWebService();
      this.callArchiveWebService();
      this.fetchDecisionLookup();
      this.fetchSearchLookup();
      this.getTheToken();
    }
  }

  callMeetingWebService() {
    let loggedInUser = localStorage.getItem("userid");
    let userinfo = JSON.parse(localStorage.getItem(loggedInUser));
    //let url = "User/loginAD";
    let url = "api/smeeting/all";
    this.serviceProvider.getWebService(url, '').subscribe(
      (data: any) => {
        console.log(data,"dddaaataaa")
        let meetingInfoName = loggedInUser + 'meetinginfo';
        this.storage.set(meetingInfoName, data);
      },
      (error) => {
        console.log("error",error)
        // loader.dismiss();
        // this.serviceProvider.errorHandling(error);
      }
    );
  }


  getDashBoardDetails() {
    let url = "api/smeeting/appDashboard";
    let loggedInUser = localStorage.getItem("userid");
    this.serviceProvider.getWebService(url, '').subscribe(
      (data: any) => {
        console.log('dashboard details', data);
        localStorage.setItem("user_name", data.user_name);
        localStorage.setItem("token", data.token);
        localStorage.setItem("jwtToken", data.jwtToken);
        localStorage.setItem(loggedInUser, JSON.stringify(data));
      },
      (error) => {
        // loader.dismiss();
        // this.serviceProvider.errorHandling(error);
      }
    );
  }


  callArchiveWebService() {
    let loggedInUser = localStorage.getItem("userid");
    let url = "api/cds/all";
    this.serviceProvider.getWebService(url, '').subscribe(
      (data: any) => {
        let archiveInfoName = loggedInUser + 'archiveinfo';
        this.storage.set(archiveInfoName, data);
      },
      (error) => {
        // loader.dismiss();
        // this.serviceProvider.errorHandling(error);
      }
    );
  }

  callApprovalWebService() {
    let loggedInUser = localStorage.getItem("userid");
    let url = "api/resolution/all";
    this.serviceProvider.getWebService(url, '').subscribe(
      (data: any) => {
        console.log('this.data' + JSON.stringify(data));
        let approvalInfoName = loggedInUser + 'approvalinfo';
        this.storage.set(approvalInfoName, data);
      },
      (error) => {
        //loader.dismiss();
        //this.serviceProvider.errorHandling(error);
      }
    );
  }

  fetchDecisionLookup() {
    //let url = "User/loginAD";
    let url = "api/decision/lookups";
    this.serviceProvider.getWebService(url, '').subscribe(
      (data: any) => {
        console.log('this.data' + JSON.stringify(data));
        // if (data.length > 0) {
        this.storage.set('decisionslookupinfo', data);
        //}
        //loader.dismiss();
      },
      (error) => {
        console.log('fetchDecisionLookup error', error);
        // loader.dismiss();
        //this.serviceProvider.errorHandling(error);
      }
    );
  }

  fetchSearchLookup() {
    //let url = "User/loginAD";
    let url = "api/archiveMeetings/archiveMListDD";
    this.serviceProvider.getWebService(url, '').subscribe(
      (data: any) => {
        console.log('this.data' + JSON.stringify(data));
        //if (data.length > 0) {
        this.storage.set('searchlookupinfo', data);
        //}
        //loader.dismiss();
      },
      (error) => {
        console.log('fetchSearchLookup error', error);
        // loader.dismiss();
        //this.serviceProvider.errorHandling(error);
      }
    );
  }

  getImage(userid) {
    let url = "api/file/downloadPhoto/" + userid;
    console.log('getImage url ' + url);
    this.serviceProvider.getImageWebService(url, '').subscribe(
      (data: any) => {
        console.log('getImage data', data);
        let response = data
        if (response) {
          response["userid"] = userid;
          this.storeImage(response);
        }
      },
      (error) => {
        console.error('getImage error', error);
        // this.serviceProvider.errorHandling(error);
      }
    );
  }

  async storeImage(response) {
    let images = await this.storage.get('storedImages');
    // console.log("images", images);
    if (images == null) {
      let imagesArr = [];
      imagesArr.push(response);
      // console.log("imagesArr", imagesArr);
      this.storage.set('storedImages', imagesArr);
    } else {
      // console.log("images", images);
      let imagesArr = images;
      let filteredArr = _.filter(imagesArr, { 'userid': response.userid });
      // console.log("filteredArr", filteredArr);
      if (filteredArr.length == 0) {
        imagesArr.push(response);
        // console.log("images save", imagesArr);
        this.storage.set('storedImages', imagesArr);
      } else {
        // await _.remove(imagesArr, function (item) {
        //   return _.isEqual(filteredArr[0], item);
        // });
        // console.log("images removed", imagesArr);
        // imagesArr.push(response);
        // console.log("images save", imagesArr);
        // this.storage.set('storedImages', JSON.stringify(imagesArr));
      }
    }
  }

  getConfig() {
    let url = 'api/config/app/7.0.0';
    this.serviceProvider.getWebService(url, '').subscribe(
      async (data: any) => {
        console.log('getconfig data', data);
        if (data.configurations) {
          let configObj = {};
          data.configurations.forEach(element => {
            configObj[element.key] = element.value;
          });
          console.log('configObj: ' + JSON.stringify(configObj));
          localStorage.setItem('configObj', JSON.stringify(configObj));
        }
        let appVersionData = data.versionDetails;
        
        if ( appVersionData && appVersionData.statusId == "2") {
          let alert = await this.alertCtrl.create({
            header: this.translate.instant('COMMON.APPNAME'),
            
          //  enableBackdropDismiss: false,
            subHeader: this.translate.getDefaultLang() == 'en' ? appVersionData.displayMessageEn : appVersionData.displayMessageAr,
            buttons: [{
              text: this.translate.instant('COMMON.OK'),
              handler: () => {
                this.openBrowser(appVersionData.appStoreUrl);
              }
            }]
          });
          alert.present();
        }
      },
      (error) => {
        // this.serviceProvider.errorHandling(error);
      }
    );
  }

  setAppVersion(value: string) {
    this.appVersion.next(value);
  }

  async getNewConfig() {
    try {

      let minSupportedVersion = '';
      this.currentIosVersion = "6.3.4";//Update when new version is released
      if (this.platform.is('ios')) {
        this.setAppVersion(this.currentIosVersion);
      } 
      let url = environment?.baseUrl + 'entity/fetchAppVersion';
      this.serviceProvider.getVersiondetails(url, '').subscribe(
        async (data)=>{
          if (this.platform.is('ios')) {
            this.setAppVersion(this.currentIosVersion);
            minSupportedVersion = data?.new_version;
            this.currentIosVersion = this.currentIosVersion;
          } 

          if (this.compareVersion(minSupportedVersion, this.currentIosVersion) > 0) {
            const ios_url = data?.app_store_url;
            let alert = await this.alertCtrl.create({
              header: this.translate.instant('COMMON.APPNAME'),
              subHeader: 'A new Version Available for download',
              buttons: [{
                text: 'Ok',
                handler: () => {
                  if (this.platform.is('ios'))
                    this.openBrowser(ios_url);
                  
                }
              }]
            });
            await alert.present();
          }

        },(err) =>{

        }
      )
     
    } catch (error) {
      console.log("error", error)
    }
  }


  compareVersion(v1, v2) {
    if (typeof v1 !== 'string') return false;
    if (typeof v2 !== 'string') return false;
    v1 = v1.split('.');
    v2 = v2.split('.');
    const k = Math.min(v1.length, v2.length);
    for (let i = 0; i < k; ++i) {
      v1[i] = parseInt(v1[i], 10);
      v2[i] = parseInt(v2[i], 10);
      if (v1[i] > v2[i]) return 1;
      if (v1[i] < v2[i]) return -1;
    }
    return v1.length == v2.length ? 0 : (v1.length < v2.length ? -1 : 1);
  }

  getAppReviewConfig() {
   let result = localStorage.getItem("isReview") == null ? true : false;
    let appVersionData = "1";
    if (appVersionData == "1") {
     console.log("ssss");
   //  this.navCtrl.setRoot(SignupPage);
    }
    return true;


    // let url = 'api/config/review';
    // this.serviceProvider.getWebService(url, '').subscribe(
    //   (data: any) => {
    //     console.log('getconfig data', data);
    //     if (data.configurations) {
    //       let configObj = {};
    //       data.configurations.forEach(element => {
    //         configObj[element.key] = element.value;
    //       });
    //       console.log('configObj: ' + JSON.stringify(configObj));
    //    //   localStorage.setItem('configObj', JSON.stringify(configObj));
    //     }
    //     let appVersionData = "1";
    //     if (appVersionData == "1") {
    //      console.log("ssss");
    //      this.navCtrl.setRoot(SignupPage, null);
    //     }
    //   },
    //   (error) => {
    //     // this.serviceProvider.errorHandling(error);
    //   }
    // );
  }




  openBrowser(url) {
    this.safariViewController.isAvailable()
      .then((available: boolean) => {
        if (available) {
          this.safariViewController.show({
            url: url,
            hidden: false,
            animated: false,
            transition: 'curl',
            enterReaderModeIfAvailable: true,
          })
            .subscribe((result: any) => {
              if (result.event === 'opened') console.log('Opened');
              else if (result.event === 'loaded') console.log('Loaded');
              else if (result.event === 'closed') {
                this.getConfig();
                console.log('Closed')
              };
            },
              (error: any) => console.error(error)
            );
        } else {
          // use fallback browser, example InAppBrowser
          window.open(url, '_blank', 'location=yes');
        }
      }
      );
  }

  getTheToken() {
    if (this.isPlatform) {
      // this.fcm.getToken().then(token => {
      //   console.log("getToken ", token);
      //   if ((token != null || token != "") && token != localStorage.getItem("fcmRegistrationId")) {
      //     localStorage.setItem("fcmRegistrationId", token);
      //     localStorage.setItem("pushNotificationEnabled", localStorage.getItem("pushNotificationEnabled") || "true");
      //     this.registerNotificationService();
      //   } else if (localStorage.getItem("notificationRegisterID") == null) {
      //     localStorage.setItem("fcmRegistrationId", token);
      //     localStorage.setItem("pushNotificationEnabled", localStorage.getItem("pushNotificationEnabled") || "true");
      //     this.registerNotificationService();
      //   }
      // });
      // this.fcm.onTokenRefresh().subscribe(token => {
      //   console.log("onTokenRefresh ", token);
      //   if ((token != null || token != "") && token != localStorage.getItem("fcmRegistrationId")) {
      //     localStorage.setItem("fcmRegistrationId", token);
      //     localStorage.setItem("pushNotificationEnabled", localStorage.getItem("pushNotificationEnabled") || "true");
      //     this.registerNotificationService();
      //   } else if (localStorage.getItem("notificationRegisterID") == null) {
      //     localStorage.setItem("fcmRegistrationId", token);
      //     localStorage.setItem("pushNotificationEnabled", localStorage.getItem("pushNotificationEnabled") || "true");
      //     this.registerNotificationService();
      //   }
      // });
    }
  }

  registerNotificationService() {
    if (this.isPlatform) {
      console.log('registerNotificationService');

      let url = "api/config/regAndUpdateFcm";
      let requestObj = {
        deviceId: localStorage.getItem("UDID"),
        fcmRegistration: localStorage.getItem("fcmRegistrationId"),
        language: localStorage.getItem('lang') == null ? 'en' : localStorage.getItem('lang'),
        isActiveForPush: localStorage.getItem("pushNotificationEnabled") == "true" ? "Yes" : "No",
        id: localStorage.getItem("notificationRegisterID")
      };

      console.log('registerNotificationService request: ' + JSON.stringify(requestObj));
      this.serviceProvider.postWebService(url, requestObj, "").subscribe(
        (data: any) => {
          console.log("registerNotificationService data" + JSON.stringify(data));
          localStorage.setItem("notificationRegisterID", data.appRegisterID);
        },
        (error) => {
          console.log('registerNotificationService' + JSON.stringify(error));
          //this.serviceProvider.errorHandling(error);
        }
      );
    }
  }

  private updateMessagesFromServer(messages: Messages): Observable<Messages> {
    console.log('updating messages');
    console.log(messages);
    var request = { lastupdated: messages.lastUpdated, user_id: messages.userid };
    let url = "api/login/chatUpdate";
    return this.serviceProvider
      .postWebService(url, request, '')
      .map((newMessageList: Message[]) => {
        for (let message of newMessageList) {
          let chatTitle;
          if (message.sentto == messages.userid) {
            chatTitle = message.from;
          } else {
            chatTitle = message.sentto;
          }
          let chatMessageList = messages.messages[chatTitle] || [];
          chatMessageList.push(message);
          messages.messages[chatTitle] = chatMessageList;

          if (message.createdAt > messages.lastUpdated) {
            messages.lastUpdated = message.createdAt;
          }
        }
        console.log('updated messages');
        console.log(messages);
        return messages;
      });
  }

  private chatMessages: Observable<Messages> = Observable.defer(() => {
    // TODO: makeSocketConnection ?
    let userid = localStorage.getItem('userid');
    console.log('getting cached messages for ' + userid);
    return Observable
      .from(this.storage.get(userid + 'allchatmessages'))
      .map((messages: Messages) => {
        return messages || {
          userid: userid,
          lastUpdated: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString(),
          messages: {},
        };
      });
  }).mergeMap(cachedMessages => {
    console.log('got cached messages');
    console.log(cachedMessages);
    let socketsList: Observable<any>[] = [];
    socketsList.push(this.socket.fromEvent<any>(cachedMessages.userid));
    let userDetails = JSON.parse(localStorage.getItem(cachedMessages.userid));
    let groups = userDetails.committeList;
    for (let group of groups) {
      var groupName = 'team' + group.id;
      socketsList.push(this.socket.fromEvent<any>(groupName));
    }
    return Observable
      .merge(...socketsList)
      .debounceTime(500)
      .startWith(null)
      .mergeScan((prevMessages, socketValueIgnored) => {
        return this.updateMessagesFromServer(prevMessages);
      }, cachedMessages)
      .startWith(cachedMessages);
  }).do(messages => {
    console.log('saving messages');
    console.log(messages);
    this.storage.set(messages.userid + 'allchatmessages', messages);
  }).publishReplay(1).refCount();

  getChatMessagesForTitle(chatTitle: string): Observable<Message[]> {
    return this.chatMessages.map(messages => {
      let arr = messages.messages[chatTitle] || [];
      if (arr.length > 0) {
        this.emittedMessagesForTitle.next({
          title: chatTitle,
          createdAt: arr[arr.length-1].createdAt,
        });
      }
      return arr;
    });
  }

  private emittedMessagesForTitle = new Subject<{ title: string, createdAt: string }>();

  private chatMessagesLastRead: Observable<MessagesLastRead> = Observable.defer(() => {
    let userid = localStorage.getItem('userid');
    console.log('getting messages last read for ' + userid);
    return Observable
      .from(this.storage.get(userid + 'chatmessageslastread'))
      .map((lastRead: MessagesLastRead) => {
        return lastRead || {};
      });
  }).mergeMap(lastRead => {
    console.log('got messages last read');
    console.log(lastRead);
    return this.emittedMessagesForTitle
      .asObservable()
      .mergeScan((prevLastRead, emittedMessage) => {
        console.log('updating messages last read with');
        console.log(emittedMessage);
        prevLastRead[emittedMessage.title] = emittedMessage.createdAt;
        return Observable.of(prevLastRead);
      }, lastRead)
      .startWith(lastRead);
  }).do(lastRead => {
    let userid = localStorage.getItem('userid');
    console.log('saving messages last read');
    console.log(lastRead);
    this.storage.set(userid + 'chatmessageslastread', lastRead);
  }).publishReplay(1).refCount();

  getChatMessagesUnread(): Observable<{ "*": boolean, [title: string]: boolean }> {
    return Observable
      .combineLatest(this.chatMessages, this.chatMessagesLastRead)
      .map(x => {
        // console.log('combining chatMessages and chatMessagesLastRead');
        // console.log(x);

        let messages = x[0];
        let lastRead = x[1];

        let results: { "*": boolean, [title: string]: boolean } = { "*": false };
        for (let title of Object.keys(messages.messages)) {
          // console.log("does " + title + " have unread messages?")
          let titleLastRead = lastRead[title];
          // console.log("titleLastRead: " + titleLastRead);
          let titleMessages = messages.messages[title];
          if (titleMessages.length > 0) {
            let messageCreatedAt = titleMessages[titleMessages.length - 1].createdAt;
            // console.log("messageCreatedAt: " + messageCreatedAt);
            if ((messageCreatedAt && !titleLastRead) || messageCreatedAt > titleLastRead) {
              results[title] = true;
              results["*"] = true;
            }
          }
        }
        // console.log('unread messages results');
        // console.log(results);
        return results;
      });
  }

}

interface Messages {
  userid: string,
  lastUpdated: string,
  messages: { [title: string]: Message[] },
}

interface Message {
  from: string,
  sentto: string,
  text: string,
  createdAt: string,
  fullname: string,
}

interface MessagesLastRead {
  [title: string]: string,
}
