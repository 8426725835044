import { Component, OnInit,ViewChild  } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
// import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { SignaturePad } from 'angular2-signaturepad';
import * as _ from 'lodash';


@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements OnInit {


  @ViewChild(SignaturePad) public signaturePad: SignaturePad;

  public signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 570,
    'canvasHeight':500
  };
  public signaturePadOptionsmbl: Object = {
    'minWidth': 2,
    'canvasWidth': 320,
    'canvasHeight':310
  };
  devicebool : boolean = false;
  loggedInUser = localStorage.getItem("userid");

  constructor(public modalController: ModalController, private platform: Platform) {
    this.loggedInUser = localStorage.getItem("userid");
    if(this.platform.is('ipad') || this.platform.is('tablet')){
      this.devicebool = false;
    }else{
      this.devicebool = true;
    }

  }
public signatureImage :string;
  ngOnInit() {}

  async closeModal() {
    await this.modalController.dismiss();

  }


  ionViewDidEnter() {
    //this.signaturePad.clear()
    this.canvasResize();
  }

  canvasResize() {
  //  console.log('canvasResize');
    // let canvas = document.querySelector('canvas');
    // var ratio = window.devicePixelRatio || 1;
    // canvas.getContext("2d").scale(ratio, ratio);
 //   console.log('canvas.offsetWidth', canvas.offsetWidth);
 //   console.log('canvas.offsetHeight', canvas.offsetHeight);
    // this.signaturePad.set('minWidth', 1);

    // this.signaturePad.set('canvasWidth', canvas.offsetWidth);
    // this.signaturePad.set('canvasHeight', canvas.offsetHeight);
  }

  async drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
  //   this.signatureImage = this.signaturePad.toDataURL()
  //   console.log(this.signatureImage);
  //  // this.signatureImage = await this.cropSignatureCanvas(canvas);
  //  // let splitArr = _.split(this.signatureImage, ',');
  //  // console.log('this.splitArr', splitArr);
  //   localStorage.setItem("signname", this.signatureImage);
  //   console.log('this.signatureImage', this.signatureImage);
  //  // localStorage.setItem(this.signatureImage, );
  //   this.modalController.dismiss(this.signatureImage);


    this.loggedInUser = localStorage.getItem("userid");
    let signname = this.loggedInUser + 'sign';
    let canvas = document.querySelector('canvas');
    this.signatureImage = await this.cropSignatureCanvas(canvas);
    let splitArr = _.split(this.signatureImage, ',');
 //   console.log('this.splitArr', splitArr);
    localStorage.setItem(signname, splitArr[1]);
 //   console.log('this.signatureImage', this.signatureImage);
    this.modalController.dismiss(this.signatureImage);


  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }
  cropSignatureCanvas(canvas) {
    // First duplicate the canvas to not alter the original
    var croppedCanvas = document.createElement('canvas'),
      croppedCtx = croppedCanvas.getContext('2d');

    croppedCanvas.width = canvas.width;
    croppedCanvas.height = canvas.height;
    croppedCtx.drawImage(canvas, 0, 0);

    // Next do the actual cropping
    try{
    var w = croppedCanvas.width,
      h = croppedCanvas.height,
      pix = { x: [], y: [] },
      imageData = croppedCtx.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height),
      x, y, index;
    }
    catch (error) {
      console.error("Failed to get image data:", error);
      return null; // Return null or handle the error as appropriate
    }

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x);
          pix.y.push(y);

        }
      }
    }
    pix.x.sort(function (a, b) { return a - b });
    pix.y.sort(function (a, b) { return a - b });
    var n = pix.x.length - 1;

    w = pix.x[n] - pix.x[0];
    h = pix.y[n] - pix.y[0];
    var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

    croppedCanvas.width = w;
    croppedCanvas.height = h;
    croppedCtx.putImageData(cut, 0, 0);

    return croppedCanvas.toDataURL();
  }


  drawCancel() {
    //this.navCtrl.push(HomePage);
    // this.viewCtrl.dismiss();
  }
  drawClear(){
    this.signaturePad.clear();
  }
  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop();
      this.signaturePad.fromData(data);
    }
  }
}
