import { Component, NgZone } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { OfflineServicesProvider } from './providers/offline-services/offline-services';
import { Subscription } from '../../node_modules/rxjs';
// import { FingerprintAIO ,FingerprintOptions} from '@ionic-native/fingerprint-aio/ngx';
import { FingerprintAIO, FingerprintOptions } from '@ionic-native/fingerprint-aio/ngx';
import { Device } from '@ionic-native/device/ngx';
// import { FCM } from '@ionic-native/fcm/ngx';

import { Socket } from 'ngx-socket-io';
import { CommonServicesProvider } from './providers/common.services/common.services';
import { HttpClient } from '../../node_modules/@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { TranslateService } from '../../node_modules/@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './providers/common.services/language.service';
import { Storage } from '@ionic/storage';
import { NavigationExtras } from '@angular/router';
import { SocketServiceService } from './providers/socket-service.service';
import { StorageService } from './providers/common.services/storage.service';
declare var RadaeePDFPlugin;
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  // fingerPrintOptions: FingerprintOptions;
  onResumeSubscription: Subscription;
  onPauseSubscription: Subscription;
  isPlatform = true;
  fingerPrintOptions: FingerprintOptions;


  pendingEsignCount = 0;
  pendingApprovalCount = 0;
  pendingDecisionCount = 0;

  constructor(
    private platform: Platform,
    public serviceProvider: CommonServicesProvider,
    private splashScreen: SplashScreen,
    public translate: TranslateService,
    private langDir: LanguageService,
    private device: Device,
    private ngZone: NgZone,
    private storage: Storage,
    private navCtrl: NavController,

    // private fcm: FCM,
    private statusBar: StatusBar,
    private socket: SocketServiceService,
    private fingerprintAIO: FingerprintAIO,
    private offlineServicesProvider: OfflineServicesProvider,
  ) {
    // this.fingerPrintOptions = { clientId: 'Alpha Meeting' };
    // this.fingerPrintOptions = { title: 'BioMetric Auth', subtitle: "AM-EMP" };
    this.fingerPrintOptions = { title: 'BioMetric Auth', subtitle: "AlphaMeeting++ 6.0.1" };
    this.initializeApp();
    this.initTranslate();
   // localStorage.getItem("userid") == null ?  : TabsPage;
    (window as any).handleOpenURL = (url: string) => {
      console.log("handleOpenURL----------59", url)
      // this context is called outside of angular zone!
      setTimeout(() => {
        // so we need to get back into the zone..
        this.ngZone.run(() => {
          // this is in the zone again..
          this.handleOpenUrl(url);
        });
      }, 0);
    };

    // check if app was opened by custom url scheme
    const lastUrl: string = (window as any).handleOpenURL_LastURL || "";
    if (lastUrl && lastUrl !== "") {
      delete (window as any).handleOpenURL_LastURL;
      this.handleOpenUrl(lastUrl);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      try {
        this.offlineServicesProvider.getNewConfig();
        this.statusBar.styleDefault();
        //  this.statusBar.backgroundColorByName('green')
        const my_statusbar_color = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary');
        console.log("my_statusbar_color", my_statusbar_color)
        //  this.statusBar.backgroundColorByHexString(my_statusbar_color)
        this.statusBar.overlaysWebView(true);

        // set status bar to white
        this.statusBar.backgroundColorByHexString('#007178');
        this.splashScreen.hide();
        // let status bar overlay webview


        if (localStorage.getItem("userid") != null) {
          console.log('userid is available');
          localStorage.removeItem("selectedTeam");
          localStorage.setItem('showMeetingFiles', JSON.stringify(true));
          localStorage.setItem("isOffline", "false");
          this.navCtrl.navigateRoot('/tabs');
          if (localStorage.getItem("securityEnabled") == "true" || localStorage.getItem("securityEnabled") == null) {
            this.checkFingerPrint();
            console.log('securityEnabled');
          } else {
            console.log('securitydisabled');
            this.makeSocketConnection();
            this.offlineServicesProvider.offlineServiceFetch();
          }
        } else {
          console.log('userid is not available');
          // this.navCtrl.navigateRoot('/login');
        }

        if (this.isPlatform) {
          console.log('this.device.uuid', this.device.uuid);
          localStorage.setItem("UDID", this.device.uuid);
          // this.fcm.onNotification().subscribe(data => {
          //   console.log("Received FCM onNotification" + JSON.stringify(data));
          // });
          var notificationOpenedCallback = function (jsonData) {
            console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
          };
          // window["plugins"].OneSignal
          //   .startInit("0d621628-8e07-46cc-a88d-d2037290a0c1")
          //   .handleNotificationOpened(notificationOpenedCallback)
          //   .endInit();

          // AJM -- 0d621628-8e07-46cc-a88d-d2037290a0c1

            window["plugins"].OneSignal.setAppId("6cec087f-1316-4d41-a423-8e7f9dfc2640");
            window["plugins"].OneSignal.setNotificationOpenedHandler(function(jsonData) {
                console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
            });

            window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
              console.log("User accepted notifications: " + accepted);
          });
        }



        // RadaeePDFPlugin.setTitleBGColor(
        //   {
        //      color: 0xffff0000
        //   },
        //   function(message){
        //     console.log("Success: " + message);
        //   },
        //   function(err){
        //     console.log("Failure: " + err);
        //   });
      } catch (e) {
        console.log(' error: ', e);
      }
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.

      RadaeePDFPlugin.activateLicense(
        {   //iOS's demo premium license for other license check:
          //http://www.radaeepdf.com/support/knowledge-base?view=kb&kbartid=4
          //http://www.radaeepdf.com/support/knowledge-base?view=kb&kbartid=8
          // licenseType: 2, //0: for standard license, 1: for professional license, 2: for premium license
          // company: "Rare Tech", //the company name you entered during license activation
          // email: "santhana@raretechonline.com", //the email you entered during license activation
          // key: "ZIN6DW-29QVAT-EL8OBO-2Q8IZ2-D6DBJA-SBH470" //your license activation key

          // Sico
          //Bundle id
          //com.raretechonline.alphameetingsico.app
          // licenseType: 1, //0: for standard license, 1: for professional license, 2: for premium license
          // company: "Raretech", //the company name you entered during license activation
          // email: "santhana@raretechonline.com", //the email you entered during license activation
          // key: "G3O7JE-PUD9UQ-6KPBYU-UGJSMU-D6DBJA-SBH470" //your license activation key

          // AJM

          // licenseType: 1, //0: for standard license, 1: for professional license, 2: for premium license
          // company: "M R EBRAHIM", //the company name you entered during license activation
          // email: "santhanamts@gmail.com", //the email you entered during license activation
          // key: "XI5W27-C2JWX8-ZKL6FG-2B2DKV-HQV8WG-K4GLKD" //your license activation key



          // Others
          // licenseType: 2, //0: for standard license, 1: for professional license, 2: for premium license

          //           company: "Rare Tech", //the company name you entered during license activation

          //           email: "santhana@raretechonline.com", //the email you entered during license activation

          //           key: "ZIN6DW-29QVAT-EL8OBO-2Q8IZ2-D6DBJA-SBH470" //your license activation key

//           AJM : com.raretechonline.alphameeting.koohji
// Sico : com.raretechonline.alphameetingsico.app
// Matahin : com.raretechonline.alphameeting.app
// App Store : com.raretechonlinealphameeting.app
          // App Store License 
          licenseType: 1, 
          company: "Raretech", 
          email: "santhanamts@gmail.com", 
          key: "H5LO8A-8GU3WU-6KPBYU-UGJSMU-HQV8WG-K4GLKD"

        },
        function (message) {
          console.log("RadaeePDFPlugin Success: " + message);
        },
        function (err) {
          console.log("RadaeePDFPlugin Failure: " + err);
        });

      console.log("different commit test");
    });




    this.onResumeSubscription = this.platform.resume.subscribe(async () => {
      console.log('onResumeSubscription');
      if (localStorage.getItem("userid") != null) {
        console.log('userid is available');
        if (localStorage.getItem("isPassCodeEnabled") == "true") {
          console.log('Passcode is enabled');
          let passcodekey = await this.storage.get('passcodeKey');
          var request = {
            key: passcodekey
          };
          console.log('request', request);
          let url = "api/login/checkUserDeviceGrant";
          await this.serviceProvider.postWebService(url, request, '').subscribe(
            (data: any) => {
              console.log('callSubmitWebService this.data' + JSON.stringify(data));
              console.log('secResume', localStorage.getItem("securityEnabled"));
              if (localStorage.getItem("securityEnabled") == "true" || localStorage.getItem("securityEnabled") == null) {
                if(!this.platform.is('android')){
                this.checkFingerPrint();
                }
                console.log('securityEnabled');
              } else {
                console.log('securitydisabled');
                this.makeSocketConnection();
                this.offlineServicesProvider.offlineServiceFetch();
              }
            },
            (error) => {
              console.log(error)
              if (error.status == 401) {
                this.storage.set('passcodeKey', null);
                this.storage.set('passcode', null);
                // this.navCtrl.setRoot(PasscodePage, null);
                this.navCtrl.navigateRoot('/PasscodePage');

              } else {
                this.serviceProvider.errorHandling(error);
              }

            });

          if (localStorage.getItem("userid") != null) {
            this.callApprovalWebService();
            this.calleSignListInfoWebService();
            this.callDecisionLookupWebService();
          }
        } else {
          //localStorage.removeItem("selectedTeam");
          //localStorage.setItem('showMeetingFiles', JSON.stringify(true));
          console.log('userid is available');
          if(!this.platform.is('android')){
            if (localStorage.getItem("securityEnabled") == "true" || localStorage.getItem("securityEnabled") == null) {
              this.checkFingerPrint();
              console.log('securityEnabled');
            } else {
              console.log('securitydisabled');
              this.makeSocketConnection();
              this.offlineServicesProvider.offlineServiceFetch();
            }
          }
         

        }

      }
      else {
        console.log('userid is not available');
      }

    });

    this.onPauseSubscription = this.platform.pause.subscribe(() => {
      console.log('onPauseSubscription');
      if (localStorage.getItem("userid") != null) {
        this.socket.disconnect();
      }
    });
  }

  // initializeApp() {
  //   this.platform.ready().then(() => {
  //     try {
  //       this.statusBar.styleDefault();
  //       //  this.statusBar.backgroundColorByName('green')
  //       const my_statusbar_color = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary');
  //       console.log("my_statusbar_color", my_statusbar_color)
  //       //  this.statusBar.backgroundColorByHexString(my_statusbar_color)
  //       this.statusBar.overlaysWebView(true);

  //       // set status bar to white
  //       this.statusBar.backgroundColorByHexString('#007178');
  //       this.splashScreen.hide();
  //       // let status bar overlay webview


  //       if (localStorage.getItem("userid") != null) {
  //         console.log('userid is available');
  //         localStorage.removeItem("selectedTeam");
  //         localStorage.setItem('showMeetingFiles', JSON.stringify(true));
  //         localStorage.setItem("isOffline", "false");
  //         this.navCtrl.navigateRoot('/tabs');
  //         if (localStorage.getItem("securityEnabled") == "true" || localStorage.getItem("securityEnabled") == null) {
  //           this.checkFingerPrint();
  //           console.log('securityEnabled');
  //         } else {
  //           console.log('securitydisabled');
  //           this.makeSocketConnection();
  //           this.offlineServicesProvider.offlineServiceFetch();
  //         }
  //       } else {
  //         console.log('userid is not available');
  //        // this.navCtrl.navigateRoot('/login');
  //       }

  //       if (this.isPlatform) {
  //         console.log('this.device.uuid', this.device.uuid);
  //         localStorage.setItem("UDID", this.device.uuid);
  //         // this.fcm.onNotification().subscribe(data => {
  //         //   console.log("Received FCM onNotification" + JSON.stringify(data));
  //         // });
  //         var notificationOpenedCallback = function (jsonData) {
  //           console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  //         };

  //         //bFM
  //         // window["plugins"].OneSignal
  //         //   .startInit("08744857-1e6e-4420-b22b-8ad6a3a11faf")
  //         //   .handleNotificationOpened(notificationOpenedCallback)
  //         //   .endInit();

  //         //AJM
  //           window["plugins"].OneSignal
  //           .startInit("0d621628-8e07-46cc-a88d-d2037290a0c1")
  //           .handleNotificationOpened(notificationOpenedCallback)
  //           .endInit();



  //           // TEsting
  //           // window["plugins"].OneSignal
  //           // .startInit("ac9a8384-7a0c-4932-be38-f7fbee808c9f")
  //           // .handleNotificationOpened(notificationOpenedCallback)
  //           // .endInit();

  //           // 
  //           // window["plugins"].OneSignal
  //           // .startInit("08744857-1e6e-4420-b22b-8ad6a3a11faf")
  //           // .handleNotificationOpened(notificationOpenedCallback)
  //           // .endInit();



  //       }

  //       RadaeePDFPlugin.activateLicense(
  //         {   //iOS's demo premium license for other license check:
  //           //http://www.radaeepdf.com/support/knowledge-base?view=kb&kbartid=4
  //           //http://www.radaeepdf.com/support/knowledge-base?view=kb&kbartid=8
  //           // licenseType: 2, //0: for standard license, 1: for professional license, 2: for premium license
  //           // company: "Rare Tech", //the company name you entered during license activation
  //           // email: "santhana@raretechonline.com", //the email you entered during license activation
  //           // key: "ZIN6DW-29QVAT-EL8OBO-2Q8IZ2-D6DBJA-SBH470" //your license activation key


  //           //sico 2021 dec lic
  //           // licenseType: 1, //0: for standard license, 1: for professional license, 2: for premium license
  //           // company: "Raretech", //the company name you entered during license activation
  //           // email: "santhana@raretechonline.com", //the email you entered during license activation
  //           // key: "G3O7JE-PUD9UQ-6KPBYU-UGJSMU-D6DBJA-SBH470" //your license activation key



  //           // Sico
  //           //Bundle id
  //           // com.raretechonline.alphameeting.sico
  //           licenseType: 1, //0: for standard license, 1: for professional license, 2: for premium license
  //           company: "Raretech", //the company name you entered during license activation
  //           email: "support@raretechonline.com", //the email you entered during license activation
  //           key: "NSNT5E-E7SHAG-6KPBYU-UGJSMU-TI22SV-K1PZXU" //your license activation key


  //            // AJM - purchase date 12 Jun 2022
  //           //Bundle id
  //           // com.raretechonline.alphameeting.koohji 
  //           // licenseType: 1, //0: for standard license, 1: for professional license, 2: for premium license
  //           // company: "M R EBRAHIM", //the company name you entered during license activation
  //           // email: "santhanamts@gmail.com", //the email you entered during license activation
  //           // key: "XI5W27-C2JWX8-ZKL6FG-2B2DKV-HQV8WG-K4GLKD" //your license activation key


  //         },
  //         function (message) {
  //           console.log("Success: " + message);
  //         },
  //         function (err) {
  //           console.log("Failure: " + err);
  //         });

  //       // RadaeePDFPlugin.setTitleBGColor(
  //       //   {
  //       //      color: 0xffff0000
  //       //   },
  //       //   function(message){
  //       //     console.log("Success: " + message);
  //       //   },
  //       //   function(err){
  //       //     console.log("Failure: " + err);
  //       //   });
  //     } catch (e) {
  //       console.log('RadaeePDFPlugin error: ', e);
  //     }
  //     // Okay, so the platform is ready and our plugins are available.
  //     // Here you can do any higher level native things you might need.
  //     console.log("different commit test");
  //   });


  //   this.onResumeSubscription = this.platform.resume.subscribe(async () => {
  //     console.log('onResumeSubscription');
  //     if (localStorage.getItem("userid") != null) {
  //       console.log('userid is available');
  //       if (localStorage.getItem("isPassCodeEnabled") == "true") {
  //         console.log('Passcode is enabled');
  //         let passcodekey = await this.storage.get('passcodeKey');
  //         var request = {
  //           key: passcodekey
  //         };
  //         console.log('request', request);
  //         let url = "api/login/checkUserDeviceGrant";
  //         await this.serviceProvider.postWebService(url, request, '').subscribe(
  //           (data: any) => {
  //             console.log('callSubmitWebService this.data' + JSON.stringify(data));
  //             console.log('secResume', localStorage.getItem("securityEnabled"));
  //             if (localStorage.getItem("securityEnabled") == "true" || localStorage.getItem("securityEnabled") == null) {
  //               this.checkFingerPrint();
  //               console.log('securityEnabled');
  //             } else {
  //               console.log('securitydisabled');
  //               this.makeSocketConnection();
  //               this.offlineServicesProvider.offlineServiceFetch();
  //             }
  //           },
  //           (error) => {
  //             console.log(error)
  //             if (error.status == 401) {
  //               this.storage.set('passcodeKey', null);
  //               this.storage.set('passcode', null);
  //               // this.navCtrl.setRoot(PasscodePage, null);
  //               this.navCtrl.navigateRoot('/PasscodePage');

  //             } else {
  //               this.serviceProvider.errorHandling(error);
  //             }

  //           });

  //         this.callApprovalWebService();
  //         this.calleSignListInfoWebService();
  //         this.callDecisionLookupWebService();
  //       } else {
  //         //localStorage.removeItem("selectedTeam");
  //         //localStorage.setItem('showMeetingFiles', JSON.stringify(true));
  //         console.log('userid is available');
  //         if (localStorage.getItem("securityEnabled") == "true" || localStorage.getItem("securityEnabled") == null) {
  //           this.checkFingerPrint();
  //           console.log('securityEnabled');
  //         } else {
  //           console.log('securitydisabled');
  //           this.makeSocketConnection();
  //           this.offlineServicesProvider.offlineServiceFetch();
  //         }

  //       }

  //     }
  //     else {
  //       console.log('userid is not available');
  //     }

  //   });

  //   this.onPauseSubscription = this.platform.pause.subscribe(() => {
  //     console.log('onPauseSubscription');
  //     if (localStorage.getItem("userid") != null) {
  //       this.socket.disconnect();
  //     }
  //   });
  // }

  //get decision count data
  callDecisionLookupWebService() {
    //let url = "User/loginAD";
    var count = 0; var count = 0;
    let url = "api/decision/lookups";
    this.serviceProvider.getWebService(url, '').subscribe(
      (data: any) => {
        console.log('this.data' + JSON.stringify(data));
        if (data) {
          count = data.smeetingList.length;
        }
      //  localStorage.setItem("decisionCount", count.toString());
      },
      (error) => {
        // this.loader.dismiss();
        this.serviceProvider.errorHandling(error);
      }
    );
  }

  //get pending approvals

  callApprovalWebService() {
    let url = "api/resolution/all";
    var count = 0;
    this.serviceProvider.getWebService(url, '').subscribe(
      async (data: any) => {
        console.log('this.data' + JSON.stringify(data));
        // if (isEmpty) {
        //   this.approvalinfo = (this.selectedTeam == 'all' || this.selectedTeam == null) ? data : _.filter(data, { "committee_name": this.selectedTeam.name });
        // }
        // this.VotedCount =await .filter(item => item.status == 'closed').length;
        if (data) {
          count = await data.filter(item => item.status == 'open').length;
        }
        localStorage.setItem("pendingApprovalCount", count.toString());
      },
      (error) => {
        // this.loading.dismiss();
        this.serviceProvider.errorHandling(error);
      }
    );
  }

  //get pending e sign data
  calleSignListInfoWebService() {
    let url = "api/esignature/esignListForApp";

    // var eSignInfoDetails;
    var count = 0;
    this.serviceProvider.getWebService(url, '').subscribe(
      async (data: any) => {
        console.log('this.data' + JSON.stringify(data));
        // if (isEmpty) {
        //   setTimeout(async () => {
        //     eSignInfoDetails = await this.loadPDFImage(data);
        //   }, 100);           

        // }
        console.log('this.eSignInfoDetails', data);
        if (data) {
          count = await data.filter(item => item.status == 'pending').length;
        }
        localStorage.setItem("pendingEsignCount", count.toString());
        console.log("pendingCount", this.pendingEsignCount)

      },
      (error) => {
        // this.loader.dismiss();
        this.serviceProvider.errorHandling(error);
      }
    );
  }

  //get pending decision data
  // calleSignListInfoWebService() {
  //   let url = "api/esignature/esignListForApp";

  //   // var eSignInfoDetails;

  //   this.serviceProvider.getWebService(url, '').subscribe(
  //     async (data: any) => {
  //       console.log('this.data' + JSON.stringify(data));
  //       // if (isEmpty) {
  //       //   setTimeout(async () => {
  //       //     eSignInfoDetails = await this.loadPDFImage(data);
  //       //   }, 100);           

  //       // }
  //       console.log('this.eSignInfoDetails', data);
  //       this.pendingEsignCount = await data.filter(item => item.status == 'pending').length;
  //       console.log("pendingCount", this.pendingEsignCount)

  //     },
  //     (error) => {
  //       // this.loader.dismiss();
  //       this.serviceProvider.errorHandling(error);
  //     }
  //   );
  // }


  async checkFingerPrint() {
    console.log("checkFingerPrint");
    try {
      await this.platform.ready();
      this.fingerprintAIO.isAvailable().then(
        (isAvailableSuccess) => {
          console.log("fingerprintAIO isAvailableSuccess" + isAvailableSuccess);
          localStorage.setItem("isFingerPrintAvailable", "true");
          localStorage.setItem("securityEnabled", "true");
          //const result = await this.fingerprintAIO.show(this.fingerPrintOptions);
          //  this.fingerPrintOptions = { clientId: localStorage.getItem("userid") };
          //   this.fingerPrintOptions = { title: 'BioMetric Auth', subtitle: "AM-EMP" };

          this.fingerprintAIO.show(this.fingerPrintOptions).then(data => {
            console.log("fingerprintAIO data" + data);
            this.makeSocketConnection();
            this.offlineServicesProvider.offlineServiceFetch();
          }).catch(error => {
            this.serviceProvider.logout();
            console.log("fingerprintAIO catch" + error);
          })
        },
        (isAvailableError) => {
          console.log("fingerprintAIO isAvailableError" + isAvailableError);
        }
      );
    } catch (error) {
      this.makeSocketConnection();
      this.offlineServicesProvider.offlineServiceFetch();
      console.log("checkFingerPrint" + error);
    }
  }






  makeSocketConnection() {
    let userid = localStorage.getItem("userid");
    let username = localStorage.getItem("user_name");
    let token = localStorage.getItem("token");
    this.socket.connect();
    this.socket.emit('set-username', { user_id: userid, token: token, user_name: username });
  }

  initTranslate() {
    // Set the default language for translation strings, and the current language.
    let language = 'en'
    //  let language = localStorage.getItem('lang') == null ? 'en' : localStorage.getItem('lang');
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    localStorage.setItem('lang', language);
    if (language == 'en') {
      // this.platform.setDir('ltr', true);
      this.langDir.setReadingDirection('ltr');
    } else {
      //  this.platform.setDir('rtl', true);
      this.langDir.setReadingDirection('rtl');
    }
    if (localStorage.getItem('isAutoDownload') == null) {
      localStorage.setItem('isAutoDownload', "true");
    }
  }

  private handleOpenUrl(url: string) {
    console.log("handleOpenUrl -----------> 287" + url);
    if (localStorage.getItem("userid") != null) {
      let fileurlparam = { "fileurl": url };
      // this.navCtrl.setDirection(TabsPage, );
      const navigationExtras: NavigationExtras = {
        queryParams: {
          fileurl: JSON.stringify(url)
        }
      }
      this.navCtrl.navigateRoot(['/tabs/briefcase'], navigationExtras);

    } else {
      console.log("handleOpenUrl else")
      let msg = this.translate.instant('APP.LoginMandatory');
      this.serviceProvider.showToastMessage(msg);
    }
  }



}
