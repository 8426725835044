import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { TranslateService } from '../../../node_modules/@ngx-translate/core';
import { LanguageService } from '../providers/common.services/language.service';
import { CommonServicesProvider } from '../providers/common.services/common.services';
import { OfflineServicesProvider } from '../providers/offline-services/offline-services';
import { LoadingService } from '../providers/common.services/loading.service';
import { AlertController } from '../../../node_modules/@ionic/angular';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomvalidationService } from '../providers/common.services/customvalidation.service';
import { ConfirmedValidator } from './confirmed.validator';
import { Platform } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {
  language: string = null;
  oldpassword = null;
  newpassword = null;
  confirmPassword = null;
  maninToolbarShow = false;
  passwordFormgroup: FormGroup;
  passWordSubmitted = false;
  confirmSubmitted = false;
  profileImage:any;
  configInfo = localStorage.getItem("configObj") != null ? JSON.parse(localStorage.getItem("configObj")) : null;
  isEnabled = localStorage.getItem("securityEnabled") == "true" ? true : false;
  isSecureAvailable = localStorage.getItem("isFingerPrintAvailable") == "true" ? true : false;

  loggedInUser = localStorage.getItem("userid") != null ? localStorage.getItem("userid") : null;
  userinfo = this.loggedInUser != null ? JSON.parse(localStorage.getItem(this.loggedInUser)) : null;
  isAdUser = (this.userinfo != null && this.userinfo.ad_user == "1") ? true : false;
  isAutoDownload = localStorage.getItem("isAutoDownload") == "true" ? true : false;

  platform_hide: boolean = true;

  constructor(private router: Router,
    private langDir: LanguageService,
    public serviceProvider: CommonServicesProvider,
    public alertCtrl: AlertController,
    private loading: LoadingService,
    private _formBuilder: FormBuilder,
    private offlineServicesProvider: OfflineServicesProvider,
    private customValidator: CustomvalidationService,
    private platform: Platform,
    private translate: TranslateService,) {
    this.setDefaultLanguage();
    console.log('this.language' + localStorage.getItem('lang'));
    console.log('dddd', localStorage.getItem("isAutoDownload"));
  }
  submitted: true;


  get f(): { [key: string]: AbstractControl } {
    return this.passwordFormgroup.controls;
  }
  ngOnInit() {
    this.platform.ready().then(() => {
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.platform_hide = true
      } else {
        this.platform_hide = false
      }
    })
    this.passwordFormgroup = this._formBuilder.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      confirmPassword: ['', Validators.required],
    },
      {
        validator: ConfirmedValidator('newpassword', 'confirmPassword')
      })
  }

  setDefaultLanguage() {
    this.language = localStorage.getItem('lang') == null ? 'en' : localStorage.getItem('lang');
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);
    if (this.language == 'en') {
      //  this.platform.setDir('ltr', true);
      this.langDir.setReadingDirection('ltr');
    } else {
      //  this.platform.setDir('rtl', true);
      this.langDir.setReadingDirection('rtl');
    }
  }


  ionViewDidLoad() {
    console.log('ionViewDidLoad SettingsPage');
  }

  notifcationChanged() {
    console.log('isDisabled', this.isEnabled);
    let securityEnabled = localStorage.getItem("securityEnabled") == "true" ? true : false;
    if (this.isEnabled != securityEnabled) {
      localStorage.setItem("securityEnabled", JSON.stringify(this.isEnabled));
      if (this.isEnabled) {
        let msg = this.translate.instant('APP.ENABLESECURE');
        this.serviceProvider.showToastMessage(msg);
      } else {
        let msg = this.translate.instant('APP.DISABLESECURE');
        this.serviceProvider.showToastMessage(msg);
      }
    }
  }

  autodownload() {
    console.log('dd', this.isAutoDownload, " platform web ", this.platform_hide, " condi ")
    localStorage.setItem("isAutoDownload", JSON.stringify(this.isAutoDownload));
    if (this.platform_hide) {
      if (this.isAutoDownload == true) {
        let msg = this.translate.instant('APP.AutoDownloadEnabled');
        this.serviceProvider.showToastMessage(msg)
      } else {
        let msg = this.translate.instant('APP.AutoDownloadDisabled');
        this.serviceProvider.showToastMessage(msg);
      }
    }

  }

  changeLanguage(lang) {
    console.log('lang: ', lang);
    localStorage.setItem('lang', lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    console.log('this.translate defaultLang: ' + this.translate.getDefaultLang());
    console.log('this.translate currentLang: ' + this.translate.currentLang);
    if (lang == 'en') {
      //  this.platform.setDir('ltr', true);
      this.langDir.setReadingDirection('ltr');
    } else {
      //  this.platform.setDir('rtl', true);
      this.langDir.setReadingDirection('rtl');
    }
    var isOffline = localStorage.getItem("isOffline");
    if (isOffline == 'false') {
      this.offlineServicesProvider.registerNotificationService();
    }
  }

  changePassword() {
    this.loading.present();
    // if (this.newpassword.length < 8) {
    //   let msg = this.translate.instant('APP.PasswordMaxLengthError');
    //   this.serviceProvider.showToastMessage(msg);
    // }
    // else if (this.newpassword != this.confirmPassword) {
    //   let msg = this.translate.instant('APP.PASSWORDMISMATCH');
    //   this.serviceProvider.showToastMessage(msg);
    // } else {
    // this.loading.present();
    console.log("wel")
    this.submitted = true;
    if (this.passwordFormgroup.valid) {
      let url = 'api/users/changePass/' + this.passwordFormgroup.value.oldpassword + '/' + this.passwordFormgroup.value.newpassword;
      this.serviceProvider.postWebService(url, {}, '').subscribe(
        async (data: any) => {
          this.loading.dismiss();
          if (data && data != undefined) {
            console.log('changePassword data', data);
            this.oldpassword = null;
            this.newpassword = null;
            this.confirmPassword = null;

            let alert = await this.alertCtrl.create({
              // header: this.translate.instant('COMMON.APPNAME'),
              subHeader: this.translate.getDefaultLang() == 'en' ? data.messageEn : data.messageAr,
              //  enableBackdropDismiss: false,
              buttons: [{
                text: this.translate.instant('COMMON.OK'),
                handler: () => {
                  this.serviceProvider.logout();
                }
              }]
            });
            await alert.present();
          }
        },
        (error) => {
          this.loading.dismiss();
          this.serviceProvider.errorHandling(error);
        }
      );
      //  });
    } else {
      this.loading.dismiss();
    }
  }
  // }
  onInputPassword(e) {
    this.passWordSubmitted = true;
    this.passwordFormgroup.get('confirmPassword').setValue('');
  }

  onInputConfirm(e) {
    this.confirmSubmitted = true;
  }



 
}
