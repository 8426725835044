import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmedValidator } from '../setting/confirmed.validator';
import { CustomvalidationService } from '../providers/common.services/customvalidation.service';
import { CommonServicesProvider } from '../providers/common.services/common.services';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.page.html',
  styleUrls: ['./forget-password.page.scss'],
})
export class ForgetPasswordPage implements OnInit {

  c_year:any= new Date().getFullYear();
  version_new:any = environment.version;
  email:string ='';
  user:string ='';
  otp:any;
  submitted = false;
  passWordSubmitted = false;
  passwordFormgroup: FormGroup;
  confirmSubmitted = false;
  chanageStatus = false;
  @ViewChild('myForm') myForm!: NgForm;

  constructor(private router: Router,
    private _formBuilder: FormBuilder,
    private customValidator: CustomvalidationService,
    public serviceProvider: CommonServicesProvider,private iab: InAppBrowser,) { }

  ngOnInit() {

    this.passwordFormgroup = this._formBuilder.group({
      otpValue:[''],
      newpassword: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      confirmPassword: ['', Validators.required],
    },
      {
        validator: ConfirmedValidator('newpassword', 'confirmPassword')
      })

  }

  get f(): { [key: string]: AbstractControl } {
    return this.passwordFormgroup.controls;
  }

  isEmailValid(): boolean {
    // Perform custom email validation logic here
    // Return true if the email is valid, false otherwise
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(this.email);
  }

  back(){
    this.chanageStatus = false;
    this.email='';
    this.user ='';
    this.router.navigate(['/login'])
  }

continue(){
  const emailControl = this.myForm.controls.email;
  emailControl?.markAsTouched();
  const userControl = this.myForm.controls.user;
  userControl?.markAsTouched();
  console.log(this.myForm.form.value)
  if (this.myForm.form.valid) {
    this.serviceProvider.forgotPassword(this.myForm.form.value.email,this.myForm.form.value.user).subscribe(async (res)=>{
      console.log(res)
      if(res.status == 'success'){
        this.chanageStatus = true;
        this.serviceProvider.showToastMessagenew("",res.message,"success","bottom");
      }else{
          this.serviceProvider.showToastMessagenew("",res.message,"danger","bottom");
      }
  }, (err)=>{
    this.serviceProvider.showToastMessagenew("Access Denied !","You do not have access to this service. Please contact your Administrator to gain access.","danger","bottom");
  })
    // Form is valid, proceed with submission

  } else {
    // Form is invalid, display error messages or take appropriate action
    // this.serviceProvider.showToastMessagenew("","Please enter email","warning","bottom");
  }
}


goToLink(url: string)
  {
    console.log('hi')
    window.open(url, "_blank");
    this.iab.create(url).show();
    // this.iab.open(url,'_system')
 }

onInputPassword(e) {
  this.passWordSubmitted = true;
  this.passwordFormgroup.get('confirmPassword').setValue('');
}

onInputConfirm(e) {
  this.confirmSubmitted = true;
}
forgetPassword(){
  this.submitted = true;
  console.log(this.email,this.passwordFormgroup.value,this.otp.length)
  if(this.otp?.length >= 6){
    console.log(this.passwordFormgroup.valid,this.passwordFormgroup.value,this.otp.length)
  if(this.passwordFormgroup.valid){
    let input={
      "email":this.email,
      "pass":this.passwordFormgroup.value.newpassword,
      "otp":this.otp,
      "user_id":this.user
    }
    this.serviceProvider.resetPassword(input).subscribe(async (res)=>{
      console.log(res)
      if(res.status == 'success'){
        this.router.navigate(['/login'])
        this.serviceProvider.showToastMessagenew("",res.message,"success","bottom");
      }else{
          this.serviceProvider.showToastMessagenew("",res.message,"danger","bottom");
      }
  }, (err)=>{
    this.serviceProvider.showToastMessagenew("Access Denied !","You do not have access to this service. Please contact your Administrator to gain access.","danger","bottom");
  })
    // Form is valid, proceed with submission


  }

}else{
  this.serviceProvider.showToastMessagenew("","Otp invaild","danger","bottom");
}
}


onOtpChange(eve:any){
  this.otp =eve;
}
}
