import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';


const config: SocketIoConfig = { 
  url: '', 
  options: {
      autoConnect: false,
      transports: ['websocket'],
      path: '/api/chat'
  } 
};

@Injectable({
  providedIn: 'root'
})

export class SocketServiceService extends Socket{
  constructor() {
    if(localStorage.getItem('sitename')){
      let entityDetails = JSON.parse(localStorage.getItem('sitename'));
      config.url = entityDetails.api_url
    }
    super(config)
  } 

  disconnect() {
    this.disconnect(); 
  }
}
