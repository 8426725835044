import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ForgotSitePage } from './forgot-site/forgot-site.page';
import { LoginComponent } from './login/login.component';
import { SettingComponent } from './setting/setting.component';
import { ForgetPasswordPage } from './forget-password/forget-password.page';
import { AttendeesModalPage } from './attendees-modal/attendees-modal.page';


const routes: Routes = [
  // {
  //   path: '',
  //  component:LoginComponent
  // },

  {
    path : '',
    loadChildren: () => import('./first-screen/first-screen.module').then( m => m.FirstScreenPageModule)
  },

  {
    path: 'login',
   component:LoginComponent
  },
  {
    path: 'setting',
   component:SettingComponent
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
    {
    path: 'approvals',
    loadChildren: () => import('./approvals/approvals.module').then( m => m.ApprovalsPageModule)
  },
  {
    path: 'instant-messaging',
    loadChildren: () => import('./instant-messaging/instant-messaging.module').then( m => m.InstantMessagingPageModule)
  },
  {
    path: 'brief-case',
    loadChildren: () => import('./brief-case/brief-case.module').then( m => m.BriefCasePageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'decisions',
    loadChildren: () => import('./decisions/decisions.module').then( m => m.DecisionsPageModule)
  },
  {
    path: 'e-sign',
    loadChildren: () => import('./e-sign/e-sign.module').then( m => m.ESignPageModule)
  },
  {
    path: 'header',
    loadChildren: () => import('./header/header.module').then( m => m.HeaderPageModule)
  },
  {
    path: 'meeting-card',
    loadChildren: () => import('./meeting-card/meeting-card.module').then( m => m.MeetingCardPageModule)
  },
  {
    path: 'first-screen',
    loadChildren: () => import('./first-screen/first-screen.module').then( m => m.FirstScreenPageModule)
  },
  {
    path: 'forgot-site',
    component:ForgotSitePage
  },
  {
    path: 'forget-password',
    component:ForgetPasswordPage
  },
  {
    path: 'attendees-modal',
    component:AttendeesModalPage
  },
 











];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
