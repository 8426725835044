import { Inject, Injectable } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewCommonService {

  private LoginStatus = new Subject<string>(); // Source
  LoginStatus$ = this.LoginStatus.asObservable(); // Stream

  constructor(@Inject(DOCUMENT) private doc) {}


  public setLoggedInStatus(loginStatus)
  {
    
    this.LoginStatus.next(loginStatus);
  }
}
