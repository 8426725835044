import { Injectable, Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { Subject } from 'rxjs';

type ReadingDirection = 'ltr' | 'rtl';

@Injectable({ providedIn: 'root' })
export class LanguageService {

  private SiteLanguage = new Subject<string>(); // Source
  SiteLanguage$ = this.SiteLanguage.asObservable(); // Stream

  constructor(@Inject(DOCUMENT) private doc) {}

  public setReadingDirection(dir: ReadingDirection) {
    this.doc.dir = dir;
    this.SiteLanguage.next(this.doc.dir);
  }
}