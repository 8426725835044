import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServicesProvider } from '../providers/common.services/common.services';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { Keyboard } from '@ionic-native/keyboard/ngx';

@Component({
  selector: 'app-forgot-site',
  templateUrl: './forgot-site.page.html',
  styleUrls: ['./forgot-site.page.scss'],
})
export class ForgotSitePage implements OnInit {
  showDiv = true;
  c_year:any= new Date().getFullYear();
  version_new:any = environment?.version;
  email:any = '';
  link:any = 'https://raretechonline.com/alphameeting-privacy-policy';
  private isPlatformReady: Boolean = false;
  constructor(private router: Router,private iab: InAppBrowser,
    public serviceProvider: CommonServicesProvider,public platform: Platform,private keyboard: Keyboard) {
      platform.ready().then(
        () => {
          this.isPlatformReady = true;
          this.keyboard.onKeyboardShow().subscribe(() => {
            this.showDiv=true;
          });
          this.keyboard.onKeyboardHide().subscribe(() => {
            this.showDiv=false;
          });

        }
      )
    }

  ngOnInit() {
  }

  back(){
    this.router.navigate(['/'])
  }

  navcateUrl(){
    window.open(this.link, '_system')
  }

  goToLink(url: string)
  {
    console.log('hi')
    window.open(url, "_blank");
    this.iab.create(url).show();
    // this.iab.open(url,'_system')
 }

  forgotEntity(){
    if(this.email !=""){
        this.serviceProvider.forgotEntity(this.email).subscribe(async (res)=>{
            console.log(res)
            if(res.status == 'success'){
              this.router.navigate(['/'])
              this.serviceProvider.showToastMessagenew("",res.message,"success","bottom");
            }else{
              if(res.message !=""){
                this.serviceProvider.showToastMessagenew("",res.message,"danger","bottom");
              }else{
                this.serviceProvider.showToastMessagenew("Access Denied !","You do not have access to this service. Please contact your Administrator to gain access.","danger","bottom");
              }
            }
        }, (err)=>{
          this.serviceProvider.showToastMessagenew("Access Denied !","You do not have access to this service. Please contact your Administrator to gain access.","danger","bottom");
        })
    }else{
      this.serviceProvider.showToastMessagenew("","Please enter Email Address","warning","bottom");
    }
  }



}
